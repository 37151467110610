.public .header__container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
}
.public .header__container > a {
  text-decoration: none;
  display: flex;
  position: absolute;
}
@media (min-width: 1200px) {
  .public .header__container > a {
    align-items: center;
    position: initial;
  }
}
@media (min-width: 768px) {
  .public .header__container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .public .header__container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .public .header__container {
    width: 1170px;
    margin-top: 54px;
  }
}
.public .header__container--mobile > a {
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 20px;
  width: 40px;
  height: 26px;
}
.public .header__container--mobile > a .header__logo {
  margin: 0;
}
.public .header__navigation {
  display: flex;
  margin-left: auto;
}
.public .header__navigation button {
  font-size: 1.4rem;
  height: 3.8rem;
}
.public .header__navigation-list {
  display: flex;
  list-style: none;
}
.public .header__navigation-list-item {
  font-size: 14px;
  line-height: 20px;
  margin-right: 35px;
  line-height: 36px;
}
.public .header__navigation-list-item a {
  text-decoration: none;
}
.public .header__navigation-list-item a:hover {
  text-decoration: underline;
}
.public .header__navigation-list-item:last-child {
  margin-right: 0;
}
@media (max-width: 640px) {
  .public .header__navigation-list {
    display: none;
  }
}
.public .header__language-selector {
  margin-left: 0px;
  align-items: center;
  display: flex;
}
@media (min-width: 1200px) {
  .public .header__language-selector {
    margin-left: 35px;
  }
}
.public .header__logo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-flex;
  font-size: 26px;
  height: 26px;
  margin: 20px 0;
  width: 40px;
}
@media (min-width: 1200px) {
  .public .header__logo {
    font-size: 42px;
    width: 63px;
    height: 42px;
    margin: 0;
  }
}
.public .header__text {
  font-size: 16px;
  line-height: 45px;
  margin-left: 14px;
}
.public--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.public--fixed .header__container {
  margin: 0px auto;
}
@media (min-width: 1200px) {
  .public--fixed .header__container {
    margin: 20px auto;
  }
}
.public .go-top-icon {
  border-width: 2px;
  width: 40px;
  height: 40px;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 10px;
}
@media (max-width: 1200px) {
  .public .go-top-icon.show {
    display: flex;
  }
}
