.cookie-toastr {
  text-align: center;
  max-width: 100%;
}

.cookie-toastr__actions--confirm {
  margin-left: 10px;
  padding: 0 10px;
  cursor: pointer;
}
