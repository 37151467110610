.error-state {
  display: flex;
  width: 100%;
  margin: auto;
  flex-flow: column;
}
.error-state__image {
  margin: 12rem auto 5rem;
  width: 275px;
  height: 275px;
}
.error-state__title {
  text-align: center;
}
.error-state a {
  margin: 0 auto;
}
.error-state__detail {
  margin: 0 auto 4rem;
  text-align: center;
}
.error-state__retry {
  text-align: center;
}

.sentry-error-embed-wrapper .sentry-error-embed .form-content input,
.sentry-error-embed-wrapper .sentry-error-embed .form-content textarea {
  line-height: 20px;
  width: 100%;
  padding: 10px 14px;
  transition: border 0.2s ease-out;
  font-size: 1.4rem;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-content input:focus, .sentry-error-embed-wrapper .sentry-error-embed .form-content input:hover,
.sentry-error-embed-wrapper .sentry-error-embed .form-content textarea:focus,
.sentry-error-embed-wrapper .sentry-error-embed .form-content textarea:hover {
  box-shadow: none;
  outline: none;
  border-width: 1px;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-content input {
  height: 40px;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-submit button {
  border: 0;
  border-bottom-width: 3px;
  padding: 1px 15px;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-submit a.close {
  border: 0;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-submit a.close,
.sentry-error-embed-wrapper .sentry-error-embed .form-submit button {
  cursor: pointer;
  font-size: 1.6rem;
  height: 4rem;
  width: fit-content;
  transition: 0.3s ease-out;
  min-width: 160px;
}
