@keyframes ring {
  0% {
    transform: rotateZ(0);
  }
  1% {
    transform: rotateZ(6deg);
  }
  3% {
    transform: rotateZ(-5deg);
  }
  5% {
    transform: rotateZ(8deg);
  }
  7% {
    transform: rotateZ(-7deg);
  }
  9% {
    transform: rotateZ(6deg);
  }
  11% {
    transform: rotateZ(-5deg);
  }
  13% {
    transform: rotateZ(4deg);
  }
  15% {
    transform: rotateZ(-2deg);
  }
  17% {
    transform: rotateZ(2deg);
  }
  19% {
    transform: rotateZ(-1deg);
  }
  21% {
    transform: rotateZ(0deg);
  }
  22% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(0);
  }
}
.private .header__container {
  align-items: center;
  display: flex;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding: 1.5rem;
}
.private .header__container > a {
  text-decoration: none;
  display: flex;
  position: absolute;
}
@media (min-width: 1200px) {
  .private .header__container > a {
    align-items: center;
    position: initial;
  }
}
@media (min-width: 768px) {
  .private .header__container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .private .header__container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .private .header__container {
    width: 1170px;
  }
}
.private .header__container .create-icon {
  background-size: 20px 20px;
  background-repeat: no-repeat;
  padding-left: 25px;
  background-position: left center;
}
.private .header__container--mobile > a {
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 26px;
  width: 40px;
  height: 26px;
}
.private .header__navigation {
  display: flex;
  width: calc(100% - 62px);
  padding-left: 75px;
}
.private .header__navigation-list {
  display: flex;
  list-style: none;
  align-items: center;
  width: 100%;
  position: relative;
}
.private .header__navigation-list-item {
  display: flex;
  font-size: 14px;
  margin-right: 40px;
  line-height: 36px;
  transition: 0.3 ease-out;
  position: relative;
}
.private .header__navigation-list-item a {
  text-decoration: none;
}
.private .header__navigation-list-item:last-child {
  margin-right: 0;
}
.private .header__navigation-list-item:nth-last-child(-n+3) {
  order: 1;
}
.private .header__navigation-list-item:nth-last-child(-n+3):not(:last-child) {
  margin-right: 24px;
}
.private .header__navigation-list-item .dropdown__content {
  display: block;
  transition: 0.3s ease-out;
  position: absolute;
  margin-top: 5px;
  padding: 10px 0 0;
  z-index: 20;
  transform: translate(-50%, 0px);
  left: 50%;
  visibility: hidden;
  opacity: 0;
}
.private .header__navigation-list-item .dropdown--active .dropdown__content {
  display: block;
  border-radius: 10px;
  transform: translate(-50%, 14px);
  visibility: visible;
  opacity: 1;
}
.private .header__navigation-list-item-notifications .dropdown--active .dropdown__content {
  transform: translate(-50%, 22px);
}
.private .header__navigation-list-item-notifications:hover .notifications-menu {
  animation: ring 4s 0.2s ease-in-out infinite;
  transform-origin: 100% 0px;
}
.private .header__navigation-list-item-lava {
  position: absolute;
  bottom: -16px;
  width: 0px;
  height: 1px;
  left: 0;
  transition: 0.3s ease-out;
  opacity: 1;
}
.private .header__navigation-list-item:not(:nth-last-child(-n+3)):after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: -19px;
  height: 1px;
  transition: 0.3s ease-out;
  opacity: 0;
}
.private .header__navigation-list-item:hover:after {
  opacity: 0.5;
}
.private .header__navigation-list-item--active:after {
  opacity: 1 !important;
}
.private .header__navigation-list:after {
  content: "";
  flex-grow: 1;
  order: 0;
}
.private .header__navigation-profile {
  width: 42px;
  height: 42px;
  cursor: pointer;
  transition: 0.2s ease-out;
  position: relative;
  border-radius: 50%;
  border-width: 2px;
}
.private .header__language-selector {
  align-items: center;
  display: flex;
}
.private .header__logo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-flex;
  font-size: 42px;
  height: 42px;
  margin: 0;
  width: 63px;
}

.content {
  width: 100%;
  margin: 0px auto;
}
.content--private {
  max-width: 940px;
}

@media (min-width: 1200px) {
  .private.footer {
    width: 940px !important;
  }
}
