.private .header__navigation .profile-menu {
  flex-direction: column;
  padding: 0px 15px;
  max-width: 240px;
  width: 100%;
}
.private .header__navigation .profile-menu__user-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.private .header__navigation .profile-menu__user-email {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  margin-bottom: 5px;
}
.private .header__navigation .profile-menu__link {
  margin-bottom: 5px;
}
