.footer {
  align-items: center;
  display: flex;
  height: 140px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .footer {
    width: 720px;
  }
}
@media (min-width: 992px) {
  .footer {
    width: 940px;
  }
}
@media (min-width: 1200px) {
  .footer {
    width: 1140px;
  }
}
.footer__copyright {
  font-size: 14px;
}
.footer__navigation {
  display: flex;
  margin-left: auto;
}
.footer__navigation-list {
  display: flex;
  list-style: none;
}
.footer__navigation-list-item {
  font-size: 14px;
  line-height: 20px;
  margin-right: 35px;
  line-height: 36px;
}
.footer__navigation-list-item:last-of-type {
  margin-right: 0;
}
.footer__navigation-list-item a {
  text-decoration: none;
}
.footer__navigation-list-item a:hover {
  text-decoration: underline;
}
.footer__navigation-list-item-disable {
  display: none;
}
@media (max-width: 992px) {
  .footer {
    flex-direction: column-reverse;
    padding-bottom: 25px;
  }
  .footer__navigation {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .footer {
    height: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .footer__navigation {
    margin-left: 0;
    display: block;
    text-align: center;
    padding: 25px 20px;
    margin-top: 25px;
    border-top: 1px solid #e9e9e9;
    width: 100%;
  }
  .footer__navigation-list {
    flex-direction: column;
  }
  .footer__navigation-list-item {
    line-height: 4rem;
    margin-right: 0;
  }
  .footer__copyright {
    text-align: center;
  }
}
