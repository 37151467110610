.notifications-nav-item {
  display: flex;
  flex-direction: row;
  line-height: 2rem;
  cursor: pointer;
  border-bottom: 1px;
  padding: 10px 0px;
}
.notifications-nav-item:hover .notifications-nav-item__actions {
  display: flex;
}
.notifications-nav-item:last-child {
  border-bottom-width: 0px;
}
.notifications-nav-item__image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}
.notifications-nav-item__image {
  width: 35px;
  height: 35px;
  margin-left: 5px;
  border-radius: 39px;
  border-width: 1px;
}
.notifications-nav-item__details {
  width: calc(100% - 120px);
}
.notifications-nav-item__actions {
  display: none;
  justify-content: center;
  align-items: center;
  width: 22px;
  margin-left: auto;
  margin-right: 22px;
}
.notifications-nav-item__actions--remove {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
