.public,
.auth,
.private {
  border-bottom-width: 1px;
}
@media (min-width: 768px) {
  .public,
  .auth,
  .private {
    border: none;
  }
}
.public .mobile__navigation,
.auth .mobile__navigation,
.private .mobile__navigation {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 13px;
  width: 100%;
}
.public .mobile__navigation-toggler,
.auth .mobile__navigation-toggler,
.private .mobile__navigation-toggler {
  margin-left: 25px;
  cursor: pointer;
  height: 15.5px;
  width: 24.5px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.public .mobile__navigation-toggler span,
.auth .mobile__navigation-toggler span,
.private .mobile__navigation-toggler span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.public .mobile__navigation-toggler span:first-child,
.auth .mobile__navigation-toggler span:first-child,
.private .mobile__navigation-toggler span:first-child {
  top: 0px;
}
.public .mobile__navigation-toggler span:nth-child(2),
.auth .mobile__navigation-toggler span:nth-child(2),
.private .mobile__navigation-toggler span:nth-child(2) {
  top: 6.5px;
}
.public .mobile__navigation-toggler span:nth-child(3),
.auth .mobile__navigation-toggler span:nth-child(3),
.private .mobile__navigation-toggler span:nth-child(3) {
  top: 14.5px;
}
.public .mobile__navigation-toggler[aria-expanded=true] span:first-child,
.auth .mobile__navigation-toggler[aria-expanded=true] span:first-child,
.private .mobile__navigation-toggler[aria-expanded=true] span:first-child {
  top: 6.5px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.public .mobile__navigation-toggler[aria-expanded=true] span:nth-child(2),
.auth .mobile__navigation-toggler[aria-expanded=true] span:nth-child(2),
.private .mobile__navigation-toggler[aria-expanded=true] span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.public .mobile__navigation-toggler[aria-expanded=true] span:nth-child(3),
.auth .mobile__navigation-toggler[aria-expanded=true] span:nth-child(3),
.private .mobile__navigation-toggler[aria-expanded=true] span:nth-child(3) {
  top: 6.5px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.public .mobile__navigation-list,
.auth .mobile__navigation-list,
.private .mobile__navigation-list {
  opacity: 0;
  overflow: hidden;
  right: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  list-style: none;
  align-items: center;
  position: absolute;
  z-index: 9999;
  width: 100%;
}
.public .mobile__navigation-list.active,
.auth .mobile__navigation-list.active,
.private .mobile__navigation-list.active {
  opacity: 1;
}
.public .mobile__navigation-list-item,
.auth .mobile__navigation-list-item,
.private .mobile__navigation-list-item {
  width: 160px;
}
.public .mobile__navigation-list-item:first-child,
.auth .mobile__navigation-list-item:first-child,
.private .mobile__navigation-list-item:first-child {
  margin-top: 8rem;
}
.public .mobile__navigation-list-item--profile,
.auth .mobile__navigation-list-item--profile,
.private .mobile__navigation-list-item--profile {
  margin-top: 0 !important;
  padding: 0;
  width: 100%;
  margin-bottom: 4rem;
}
.public .mobile__top-navigation,
.auth .mobile__top-navigation,
.private .mobile__top-navigation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.public .mobile__top-navigation .dropdown,
.auth .mobile__top-navigation .dropdown,
.private .mobile__top-navigation .dropdown {
  margin-left: 5px;
}
.public .mobile__top-navigation .dropdown__content,
.auth .mobile__top-navigation .dropdown__content,
.private .mobile__top-navigation .dropdown__content {
  display: block;
  transition: 0.3s ease-out;
  position: absolute;
  margin-top: 21px;
  z-index: 20;
  transform: translate(-50%, 0px);
  left: 50%;
  visibility: hidden;
  opacity: 0;
  height: calc(100vh - 95px);
}
.public .mobile__top-navigation .dropdown__content .notifications-nav,
.auth .mobile__top-navigation .dropdown__content .notifications-nav,
.private .mobile__top-navigation .dropdown__content .notifications-nav {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
}
.public .mobile__top-navigation .dropdown__content .notifications-nav ul,
.auth .mobile__top-navigation .dropdown__content .notifications-nav ul,
.private .mobile__top-navigation .dropdown__content .notifications-nav ul {
  max-height: calc(100% - 103px);
  overflow-y: scroll;
}
.public .mobile__top-navigation .dropdown__content .notifications-nav__title,
.auth .mobile__top-navigation .dropdown__content .notifications-nav__title,
.private .mobile__top-navigation .dropdown__content .notifications-nav__title {
  padding: 24px;
}
.public .mobile__top-navigation .dropdown__content .notifications-nav-item,
.auth .mobile__top-navigation .dropdown__content .notifications-nav-item,
.private .mobile__top-navigation .dropdown__content .notifications-nav-item {
  padding: 10px 15px;
}
.public .mobile__top-navigation .dropdown__content .notifications-nav-item__image,
.auth .mobile__top-navigation .dropdown__content .notifications-nav-item__image,
.private .mobile__top-navigation .dropdown__content .notifications-nav-item__image {
  margin-left: 0;
}
.public .mobile__top-navigation .dropdown__content .notifications-nav-item__actions,
.auth .mobile__top-navigation .dropdown__content .notifications-nav-item__actions,
.private .mobile__top-navigation .dropdown__content .notifications-nav-item__actions {
  display: flex;
}
.public .mobile__top-navigation .dropdown__content .notifications-nav__footer,
.auth .mobile__top-navigation .dropdown__content .notifications-nav__footer,
.private .mobile__top-navigation .dropdown__content .notifications-nav__footer {
  padding: 12px 0;
  width: calc(100% - 50px);
  margin: 1.5rem auto 0;
}
.public .mobile__top-navigation .dropdown--active .dropdown__content,
.auth .mobile__top-navigation .dropdown--active .dropdown__content,
.private .mobile__top-navigation .dropdown--active .dropdown__content {
  display: block;
  border-radius: 10px;
  transform: translate(-50%, 14px);
  visibility: visible;
  opacity: 1;
}
.public .mobile__top-navigation .header__navigation-list-item-notifications .dropdown--active .dropdown__content,
.auth .mobile__top-navigation .header__navigation-list-item-notifications .dropdown--active .dropdown__content,
.private .mobile__top-navigation .header__navigation-list-item-notifications .dropdown--active .dropdown__content {
  transform: translate(-50%, 22px);
}
.public .mobile__top-navigation .header__navigation-list-item-notifications:hover .notifications-menu,
.auth .mobile__top-navigation .header__navigation-list-item-notifications:hover .notifications-menu,
.private .mobile__top-navigation .header__navigation-list-item-notifications:hover .notifications-menu {
  animation: ring 4s 0.2s ease-in-out infinite;
  transform-origin: 100% 0px;
}
