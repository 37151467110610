.region-warning-banner {
  display: block;
  width: 100%;
  height: 6.8rem;
}
.region-warning-banner__stay-here {
  margin-left: 2rem;
  cursor: pointer;
  text-decoration: underline;
}
.region-warning-banner__content {
  margin: 0 auto;
  z-index: 1001;
  line-height: 2.6rem;
  font-size: 1.8rem;
  padding: 10px 0;
}
@media (min-width: 768px) {
  .region-warning-banner__content {
    width: 1010px;
  }
}
@media (min-width: 992px) {
  .region-warning-banner__content {
    width: 1010px;
  }
}
@media (min-width: 1200px) {
  .region-warning-banner__content {
    width: 1010px;
  }
}
