@font-face {
  font-family: "Brandon Text Light";
  src: url("/assets/fonts/BrandonText-Light.eot");
  src: local("Brandon Text Light"), local("BrandonText-Light"), url("/assets/fonts/BrandonText-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/BrandonText-Light.woff2") format("woff2"), url("/assets/fonts/BrandonText-Light.woff") format("woff"), url("/assets/fonts/BrandonText-Light.ttf") format("truetype");
  /*font-weight: light;*/
  font-style: normal;
}
@font-face {
  font-family: "Brandon Text";
  src: url("/assets/fonts/BrandonText-Regular.eot");
  src: local("Brandon Text Regular"), local("BrandonText-Regular"), url("/assets/fonts/BrandonText-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/BrandonText-Regular.woff2") format("woff2"), url("/assets/fonts/BrandonText-Regular.woff") format("woff"), url("/assets/fonts/BrandonText-Regular.ttf") format("truetype"), url("/assets/fonts/BrandonText-Regular.svg#BrandonText-Regular") format("svg");
  /*font-weight: normal;*/
  font-style: normal;
}
@font-face {
  font-family: "Brandon Text Bold";
  src: url("/assets/fonts/BrandonText-Bold.eot");
  src: local("Brandon Text Bold"), local("BrandonText-Bold"), url("/assets/fonts/BrandonText-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/BrandonText-Bold.woff2") format("woff2"), url("/assets/fonts/BrandonText-Bold.woff") format("woff"), url("/assets/fonts/BrandonText-Bold.ttf") format("truetype"), url("/assets/fonts/BrandonText-Bold.svg#BrandonText-Bold") format("svg");
  /*font-weight: bold;*/
  font-style: normal;
}
@font-face {
  font-family: "Brandon Text Medium";
  src: url("/assets/fonts/BrandonText-Medium.eot");
  src: local("Brandon Text Medium"), local("BrandonText-Medium"), url("/assets/fonts/BrandonText-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/BrandonText-Medium.woff2") format("woff2"), url("/assets/fonts/BrandonText-Medium.woff") format("woff"), url("/assets/fonts/BrandonText-Medium.ttf") format("truetype"), url("/assets/fonts/BrandonText-Medium.svg#BrandonText-Medium") format("svg");
  /*font-weight: 500;*/
  font-style: normal;
}
@font-face {
  font-family: "Brandon Text Black";
  src: url("/assets/fonts/BrandonText-Black.eot");
  src: local("Brandon Text Black"), local("BrandonText-Black"), url("/assets/fonts/BrandonText-Black.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/BrandonText-Black.woff2") format("woff2"), url("/assets/fonts/BrandonText-Black.woff") format("woff"), url("/assets/fonts/BrandonText-Black.ttf") format("truetype");
  /*font-weight: 900;*/
  font-style: normal;
}
.button {
  border-radius: 1rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.button--primary {
  background-color: #6b0061;
  color: #fff;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  border-bottom-color: #40003b;
  border-bottom-style: solid;
}
.button--primary:hover {
  background-color: #810075;
}
.button--secondary {
  background-color: #f2f2f2;
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  border-bottom-color: #d7d7d7;
  border-bottom-style: solid;
}
.button--secondary:hover {
  background-color: #fafafa;
}
.button--half-left {
  border-radius: 1rem 0 0 1rem;
}
.button--half-right {
  border-radius: 0 1rem 1rem 0;
}
.button--facebook {
  background-color: #415dae;
  color: #fff;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.button--facebook:hover {
  background-color: #5d7abe;
}
.button--apple {
  background-color: #000;
  color: #fff;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.button:disabled {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  color: #aaa;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  cursor: default;
}
.button--invisible {
  background-color: #fff;
  border: 0;
  color: #6b0061;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.button--invisible:hover {
  background-color: #fafafa;
}
.button--active {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  color: #6b0061;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.button--inactive {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  color: #aaa;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.button--inline {
  background: none;
  text-decoration: underline;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
  vertical-align: inherit;
  outline: none;
}

.footer__copyright {
  color: #aaa;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.footer__navigation-list-item {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.footer__navigation-list-item a {
  color: #aaa;
}

.empty-state__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.empty-state__image--events {
  background-image: url("/assets/private/empty-states/events-empty-state@2x.png");
}
.empty-state__image--goals {
  background-image: url("/assets/private/empty-states/goals-empty-state@2x.png");
}
.empty-state__image--wishlists {
  background-image: url("/assets/private/empty-states/wishlist-empty-state@2x.png");
}
.empty-state h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.empty-state p {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
}

.empty-filters h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.empty-filters__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.empty-filters__image--events {
  background-image: url("/assets/private/empty-states/filters-events@2x.png");
}
.empty-filters__image--goals {
  background-image: url("/assets/private/empty-states/filter-goals@2x.png");
}

.empty-notifications__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/assets/private/empty-states/notifications-empty-state@2x.png");
}
.empty-notifications p {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
}

.error-state__image {
  background-image: url("/assets/public/something-went-wrong-icon@2x.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.error-state__title {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  line-height: 5rem;
  font-size: 3.6rem;
}
.error-state__detail {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-size: 2rem;
  line-height: 3rem;
}
.error-state__retry {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #979797;
  line-height: 3rem;
  font-size: 1.6rem;
}
.error-state__retry a {
  color: #6b0061;
  cursor: pointer;
}
.error-state__retry a:hover {
  text-decoration: underline;
}

.sentry-error-embed-wrapper .sentry-error-embed {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
  border: 1px solid #e6e6e6;
  border-radius: 1rem;
  color: #434343;
}
.sentry-error-embed-wrapper .sentry-error-embed h2 {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-content label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-transform: none;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-content input,
.sentry-error-embed-wrapper .sentry-error-embed .form-content textarea {
  border: 1px solid #cacaca;
  color: #37a894;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  border-radius: 10px;
  background: transparent;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-content input:focus, .sentry-error-embed-wrapper .sentry-error-embed .form-content input:hover,
.sentry-error-embed-wrapper .sentry-error-embed .form-content textarea:focus,
.sentry-error-embed-wrapper .sentry-error-embed .form-content textarea:hover {
  border-style: solid;
  border-color: #37a894;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-submit {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-submit .powered-by {
  display: none;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-submit button {
  background-color: #6b0061;
  color: #fff;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  border-bottom-color: #40003b;
  border-bottom-style: solid;
  border-radius: 1rem;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-submit button:hover {
  background-color: #810075;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-submit a.close {
  background-color: #fff;
  border: 0;
  color: #6b0061;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.sentry-error-embed-wrapper .sentry-error-embed .form-submit a.close:hover {
  background-color: #fafafa;
}

.text-field--error .text-input {
  border: 1px solid #a94442;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.text-input {
  border: 1px solid #cacaca;
  color: #37a894;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  border-radius: 10px;
}
.text-input:focus, .text-input:hover {
  border-style: solid;
  border-color: #37a894;
}
.text-input:disabled {
  background-color: #f2f2f2;
}

.checkbox-label:before {
  background-image: url("/assets/public/checkbox@3x.png");
}

.checkbox-input:checked + .checkbox-label:before {
  background-image: url("/assets/public/checkbox-selected@3x.png");
}
.checkbox-input--error {
  color: #a94442;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.4rem;
}

.radio-label:before {
  background-image: url("/assets/public/radio-unchecked@3x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.radio-input:checked + .radio-label:before {
  background-image: url("/assets/public/radio-checked@3x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.occasion-label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
}
.occasion-label:before {
  border-radius: 50%;
  border-style: solid;
  border-color: #e0e0e0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.occasion-label:hover:before {
  transition: all ease-in-out 0.3s;
  box-shadow: 0 0 7px 0 #e0e0e0;
}
.occasion-label--birthday:before {
  background-image: url("/assets/public/events/birthday.svg");
}
.occasion-label--baby:before {
  background-image: url("/assets/public/events/babyshower.svg");
}
.occasion-label--bachelor:before {
  background-image: url("/assets/public/events/bachelor.svg");
}
.occasion-label--holiday:before {
  background-image: url("/assets/public/events/holiday.svg");
}
.occasion-label--farewell:before {
  background-image: url("/assets/public/events/farewell.svg");
}
.occasion-label--vacation:before {
  background-image: url("/assets/public/events/vacation.svg");
}
.occasion-label--wedding:before {
  background-image: url("/assets/public/events/wedding.svg");
}
.occasion-label--house-warming:before {
  background-image: url("/assets/public/events/housewarming.svg");
}
.occasion-label--custom:before {
  background-image: url("/assets/public/events/custom1.svg");
}
.occasion-label--new:before {
  background-image: url("/assets/public/events/create.svg");
}
.events-container.public .occasion-label--house-warming:before {
  background-image: url("/assets/public/listi/housewarming-icon--disabled.svg");
  background-size: 50%;
}
.events-container.public .occasion-label--wedding:before {
  background-image: url("/assets/public/listi/wedding-icon--disabled.svg");
  background-size: 50%;
}
.events-container.public .occasion-label--farewell:before {
  background-image: url("/assets/public/listi/company-icon--disabled.svg");
}
.events-container.public .occasion-label--bachelor:before {
  background-image: url("/assets/public/listi/bachelor-icon--disabled.svg");
  background-size: 50%;
}
.events-container.public .occasion-label--baby:before {
  background-image: url("/assets/public/listi/baby-icon--disabled.svg");
  background-size: 40%;
}

.radio-input {
  border-style: solid;
  border-color: #e0e0e0;
}
.radio-input:checked + .occasion-label:before {
  border-radius: 50%;
  border-style: solid;
  border-color: #37a894;
  background-position: center center;
  background-repeat: no-repeat;
}
.radio-input:checked + .occasion-label:hover:before {
  transition: all ease-in-out 0.3s;
  box-shadow: 0 0 7px 0 #37a894;
}
.radio-input:disabled + .occasion-label {
  opacity: 0.5;
}

.occasions-field__green-checkmark {
  background-image: url("/assets/public/check-mark copy@3x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.occasions-field--error-message {
  color: #a94442;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}

.priority-label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
}
.priority-label:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/assets/public/radio-unchecked@3x.png");
}

.radio-input:checked + .priority-label:before {
  background-image: url("/assets/public/check-mark copy@3x.png");
}

.priorities-field__green-checkmark {
  background-image: url("/assets/public/check-mark copy@3x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.priorities-field--error-message {
  color: #a94442;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}

.text-field--error .date-input {
  border-style: solid;
  border-color: #a94442;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.date-input {
  border-style: solid;
  border-color: #cacaca;
  color: #37a894;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.date-input:focus, .date-input:hover {
  border-style: solid;
  border-color: #37a894;
}
.date-input--error {
  border: 1px solid #a94442;
  border-radius: 1rem;
}

.react-calendar.calendar {
  border-radius: 5px;
  border-color: #cacaca;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}
.react-calendar.calendar:before {
  border-left-style: solid;
  border-left-color: transparent;
  border-right-style: solid;
  border-right-color: transparent;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}
.react-calendar.calendar:after {
  border-left-style: solid;
  border-left-color: transparent;
  border-right-style: solid;
  border-right-color: transparent;
  border-bottom-style: solid;
  border-bottom-color: #fff;
  border-top: 0;
}
.react-calendar.calendar .react-calendar__navigation__label {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.react-calendar.calendar .react-calendar__navigation__label:hover {
  background-color: #6b0061;
  color: #fff;
  border-radius: 15px;
}
.react-calendar.calendar .react-calendar__month-view__weekdays__weekday {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-size: 1.2rem;
}
.react-calendar.calendar .react-calendar__tile.react-calendar__tile.react-calendar__month-view__days__day {
  border-radius: 15px;
}
.react-calendar.calendar .react-calendar__tile.react-calendar__tile--active.react-calendar__month-view__days__day {
  background-color: #6b0061;
  color: #fff;
}
.react-calendar.calendar .react-calendar__tile.react-calendar__tile--hasActive {
  background-color: #6b0061;
  color: #fff;
  border-radius: 15px;
}
.react-calendar.calendar .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--now:not([disabled]) {
  background-color: #6b0061;
  color: #fff;
  border-radius: 15px;
}
.react-calendar.calendar .react-calendar__tile.react-calendar__month-view__days__day {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.react-calendar.calendar .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
  color: #aaa;
}
.react-calendar.calendar .react-calendar__tile:hover:not([disabled]) {
  background-color: #6b0061;
  color: #fff;
  border-radius: 15px;
}
.react-calendar.calendar .react-calendar__navigation__arrow {
  border-radius: 15px;
}
.react-calendar.calendar .react-calendar__navigation__arrow:hover:not([disabled]) {
  background-color: #6b0061;
  color: #fff;
  border-radius: 15px;
}

.datepicker__calendar {
  border-style: solid;
  border-color: #cacaca;
}
.datepicker__calendar .icon-area__icon {
  background-image: url("/assets/public/calendar-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 center;
  margin: 0 auto;
}
.datepicker__calendar:hover {
  border-color: #37a894;
}

.dropdown-wrapper--error .Select.Select--single.dropdown .Select-control, .dropdown-wrapper--error .Select.Select--single.dropdown.has-value .Select-control, .dropdown-wrapper--error .Select.Select--single.dropdown.has-value.is-pseudo-focused .Select-control {
  border: 1px solid #a94442;
  border-radius: 1rem;
}

.Select.Select--single.dropdown .Select-control, .Select.Select--single.dropdown.has-value .Select-control, .Select.Select--single.dropdown.has-value.is-pseudo-focused .Select-control {
  border-radius: 1rem;
  border-color: #cacaca;
  box-shadow: none;
}
.Select.Select--single.dropdown .Select-control:focus, .Select.Select--single.dropdown .Select-control:hover, .Select.Select--single.dropdown.has-value .Select-control:focus, .Select.Select--single.dropdown.has-value .Select-control:hover, .Select.Select--single.dropdown.has-value.is-pseudo-focused .Select-control:focus, .Select.Select--single.dropdown.has-value.is-pseudo-focused .Select-control:hover {
  border-color: #37a894;
}
.Select.Select--single.dropdown .Select-control .Select-placeholder, .Select.Select--single.dropdown.has-value .Select-control .Select-placeholder, .Select.Select--single.dropdown.has-value.is-pseudo-focused .Select-control .Select-placeholder {
  font-size: 1.4rem;
  color: #9a9a9a;
}
.Select.Select--single.dropdown .Select-control .Select-input input, .Select.Select--single.dropdown.has-value .Select-control .Select-input input, .Select.Select--single.dropdown.has-value.is-pseudo-focused .Select-control .Select-input input {
  font-size: 1.4rem;
}
.Select.Select--single.dropdown .Select-control .Select-value, .Select.Select--single.dropdown.has-value .Select-control .Select-value, .Select.Select--single.dropdown.has-value.is-pseudo-focused .Select-control .Select-value {
  color: #37a894;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.Select.Select--single.dropdown .Select-control .Select-value .Select-value-label, .Select.Select--single.dropdown.has-value .Select-control .Select-value .Select-value-label, .Select.Select--single.dropdown.has-value.is-pseudo-focused .Select-control .Select-value .Select-value-label {
  color: #37a894;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  text-transform: capitalize;
}
.Select.Select--single.dropdown .Select-control .Select-arrow-zone .caret, .Select.Select--single.dropdown.has-value .Select-control .Select-arrow-zone .caret, .Select.Select--single.dropdown.has-value.is-pseudo-focused .Select-control .Select-arrow-zone .caret {
  background-image: url("/assets/public/dropdown-inactive@3x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.Select.Select--single.dropdown.is-focused.is-open .Select-control, .Select.Select--single.dropdown.is-focused.is-open.has-value .Select-control {
  border-color: #37a894;
}
.Select.Select--single.dropdown.is-focused.is-open .Select-arrow-zone .caret, .Select.Select--single.dropdown.is-focused.is-open.has-value .Select-arrow-zone .caret {
  background-image: url("/assets/public/dropdown-active@3x.png");
}

.Select-menu-outer {
  border-radius: 1rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.11);
}
.Select-menu-outer .Select-option {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  background: #fff;
  text-transform: capitalize;
}
.Select-menu-outer .Select-option.is-disabled {
  color: #434343;
  border-bottom: 2px solid #e0e0e0;
}
.Select-menu-outer .Select-option:hover {
  background: #fff;
  color: #37a894;
}
.Select-menu-outer .Select-option:first-child {
  border-top-left-radius: 1rem 1rem;
  border-top-right-radius: 1rem 1rem;
}
.Select-menu-outer .Select-option:last-child {
  border-bottom-left-radius: 1rem 1rem;
  border-bottom-right-radius: 1rem 1rem;
}
.Select-menu-outer .Select-option.is-selected {
  color: #37a894;
  background: #fff;
}
.Select-menu-outer .Select-option.is-selected:before {
  background-image: url("/assets/public/dropdown-check@3x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.Select-menu-outer .Select-option.is-focused {
  color: #37a894;
}

.currency-input input:read-only {
  background: #cacaca;
}
.currency-input .row,
.currency-input .row--error {
  border-radius: 1rem 1rem 1rem 1rem;
  border-style: solid;
  border-color: #cacaca;
}
.currency-input .row:focus, .currency-input .row:hover,
.currency-input .row--error:focus,
.currency-input .row--error:hover {
  border-style: solid;
  border-color: #37a894;
}
.currency-input .row:focus input, .currency-input .row:hover input,
.currency-input .row--error:focus input,
.currency-input .row--error:hover input {
  border-style: solid;
  border-color: #37a894;
}
.currency-input .row--error,
.currency-input .row--error--error {
  border-style: solid;
  border-color: #a94442;
}
.currency-input .row--error input,
.currency-input .row--error--error input {
  border-style: solid;
  border-color: #a94442;
}
.currency-input .row--error__icon-area,
.currency-input .row--error--error__icon-area {
  border-radius: 1rem 0 0 1rem;
}
.currency-input .row--error .icon-area__icon--en-US,
.currency-input .row--error--error .icon-area__icon--en-US {
  background-image: url("/assets/public/dollar-sign@3x.png");
}
.currency-input .row--error .icon-area__icon--en-GB, .currency-input .row--error .icon-area__icon--nl-NL,
.currency-input .row--error--error .icon-area__icon--en-GB,
.currency-input .row--error--error .icon-area__icon--nl-NL {
  background-image: url("/assets/public/euro-sign@3x.png");
}
.currency-input .row .icon-area__icon--en-US,
.currency-input .row--error .icon-area__icon--en-US {
  background-image: url("/assets/public/dollar-sign@3x.png");
}
.currency-input .row .icon-area__icon--en-GB, .currency-input .row .icon-area__icon--nl-NL,
.currency-input .row--error .icon-area__icon--en-GB,
.currency-input .row--error .icon-area__icon--nl-NL {
  background-image: url("/assets/public/euro-sign@3x.png");
}
.currency-input .text-input {
  border: 0;
  border-radius: 0 1rem 1rem 0;
  border-style: solid;
  border-color: #cacaca;
}

.content .text .phone-input {
  border-style: solid;
  border-color: #cacaca;
  border-radius: 10px;
}
.content .text .phone-input--error {
  border-color: #a94442;
}
.content .text .phone-input .rrui__input button {
  border: none;
}
.content .text .phone-input .react-phone-number-input__icon {
  border: none;
}
.content .text .phone-input .react-tel-input {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.content .text .phone-input .react-tel-input input {
  color: #37a894;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  border: none;
}
.content .text .phone-input .react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}
.content .text .phone-input .react-tel-input .flag-dropdown .arrow {
  border: none;
  background-image: url("/assets/public/dropdown-inactive@3x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.content .text .phone-input .react-tel-input .flag-dropdown .arrow.up {
  background-image: url("/assets/public/dropdown-active@3x.png");
  border: none;
}
.content .text .phone-input .react-tel-input .flag-dropdown ul.country-list {
  border-radius: 1rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.11);
}
.content .text .phone-input .react-tel-input .flag-dropdown ul.country-list li:hover {
  background: #fff;
  color: #37a894;
}
.content .text .phone-input .react-tel-input .flag-dropdown ul.country-list li:first-child {
  border-top-left-radius: 1rem 1rem;
  border-top-right-radius: 1rem 1rem;
}
.content .text .phone-input .react-tel-input .flag-dropdown ul.country-list li:last-child {
  border-bottom-left-radius: 1rem 1rem;
  border-bottom-right-radius: 1rem 1rem;
}
.content .text .phone-input .react-tel-input .flag-dropdown ul.country-list li.highlight {
  color: #37a894;
  background: #fff;
}
.content .text .phone-input .react-tel-input .flag-dropdown ul.country-list li.highlight:before {
  background-image: url("/assets/public/dropdown-check@3x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.content .text .phone-input .react-tel-input .flag-dropdown ul.country-list li.is-focused {
  color: #37a894;
}
.content .text .phone-input:focus, .content .text .phone-input:hover {
  border-style: solid;
  border-color: #37a894;
}

.text-area {
  border-style: solid;
  border-color: #cacaca;
  border-radius: 1rem;
}
.text-area:focus, .text-area:hover {
  border-style: solid;
  border-color: #37a894;
}
.text-area__input {
  color: #37a894;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.text-area__count {
  color: #9a9a9a;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.2rem;
}
.text-area--error {
  border-color: #a94442;
}

.photo-upload__label {
  border: 1px solid #cacaca;
  background-color: #eaeaea;
  border-radius: 50%;
}
.photo-upload__placeholder-img {
  background-image: url("/assets/public/camera-field@3x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.photo-upload__placeholder-text {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
}
.photo-upload__placeholder-white-text {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #fff;
}

.photo-upload-preview.photo-upload .photo-upload__placeholder-img {
  background-image: url("/assets/public/camera-icon-white@3x.png");
}

.public,
.auth,
.private {
  background-color: #fff;
  border-color: #eaeaea;
  border-style: solid;
}
@media (min-width: 768px) {
  .public,
  .auth,
  .private {
    border: none;
  }
}
.public .mobile__navigation-toggler span,
.auth .mobile__navigation-toggler span,
.private .mobile__navigation-toggler span {
  background: #434343;
}
.public .mobile__navigation-list,
.auth .mobile__navigation-list,
.private .mobile__navigation-list {
  background-color: #37a894;
}
.public .mobile__navigation-list-item,
.auth .mobile__navigation-list-item,
.private .mobile__navigation-list-item {
  font-size: 2rem;
  line-height: 3.8rem;
  font-weight: bold;
}
.public .mobile__navigation-list-item:not(.login-link):not(.logout-link) a,
.auth .mobile__navigation-list-item:not(.login-link):not(.logout-link) a,
.private .mobile__navigation-list-item:not(.login-link):not(.logout-link) a {
  color: #fff;
}
.public .mobile__navigation-list-item.login-link a,
.auth .mobile__navigation-list-item.login-link a,
.private .mobile__navigation-list-item.login-link a {
  color: #6c0062;
}
.public .mobile__navigation-list-item.logout-link a,
.auth .mobile__navigation-list-item.logout-link a,
.private .mobile__navigation-list-item.logout-link a {
  color: #6c0062;
}
.public .mobile__navigation-list-item:not(:last-child) a,
.auth .mobile__navigation-list-item:not(:last-child) a,
.private .mobile__navigation-list-item:not(:last-child) a {
  position: relative;
}
.public .mobile__navigation-list-item:not(:last-child) a:after,
.auth .mobile__navigation-list-item:not(:last-child) a:after,
.private .mobile__navigation-list-item:not(:last-child) a:after {
  content: "";
  position: absolute;
  right: 0;
  width: 0%;
  height: 2px;
  background-color: #fff;
  transition: 0.3s ease-out;
  bottom: 0;
}
.public .mobile__navigation-list-item:not(:last-child) a:hover:after,
.auth .mobile__navigation-list-item:not(:last-child) a:hover:after,
.private .mobile__navigation-list-item:not(:last-child) a:hover:after {
  width: 100%;
  right: -100%;
}
.public .mobile__navigation-list-item--active a:after,
.auth .mobile__navigation-list-item--active a:after,
.private .mobile__navigation-list-item--active a:after {
  width: 100% !important;
  right: -100% !important;
}
.public .mobile__navigation-list-item.language-selector,
.auth .mobile__navigation-list-item.language-selector,
.private .mobile__navigation-list-item.language-selector {
  margin-top: auto;
  margin-bottom: 3rem;
}
.public .mobile__navigation-list-item.language-selector span,
.auth .mobile__navigation-list-item.language-selector span,
.private .mobile__navigation-list-item.language-selector span {
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  letter-spacing: -0.35px;
  color: #fff;
}
.public .mobile__navigation-list-item.language-selector .button,
.auth .mobile__navigation-list-item.language-selector .button,
.private .mobile__navigation-list-item.language-selector .button {
  font-size: 1.4rem;
  height: 3.8rem;
}
.public .mobile__navigation-list-item.language-selector .button--inactive,
.auth .mobile__navigation-list-item.language-selector .button--inactive,
.private .mobile__navigation-list-item.language-selector .button--inactive {
  background-color: #fff;
}
.public .mobile__navigation-list-item.language-selector .button--half-left,
.auth .mobile__navigation-list-item.language-selector .button--half-left,
.private .mobile__navigation-list-item.language-selector .button--half-left {
  margin-right: 0.3rem;
}
.public .mobile__top-navigation .dropdown__content,
.auth .mobile__top-navigation .dropdown__content,
.private .mobile__top-navigation .dropdown__content {
  background-color: #fff;
  text-transform: none;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.public .mobile__top-navigation .dropdown__content .notifications-nav__title,
.auth .mobile__top-navigation .dropdown__content .notifications-nav__title,
.private .mobile__top-navigation .dropdown__content .notifications-nav__title {
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  font-weight: bold;
  color: #434343;
}
.public .mobile__top-navigation .dropdown__content .notifications-nav__footer,
.auth .mobile__top-navigation .dropdown__content .notifications-nav__footer,
.private .mobile__top-navigation .dropdown__content .notifications-nav__footer {
  background-color: #f2f2f2;
  border-radius: 5px;
}
.public .mobile__top-navigation .dropdown--active .dropdown__content,
.auth .mobile__top-navigation .dropdown--active .dropdown__content,
.private .mobile__top-navigation .dropdown--active .dropdown__content {
  display: block;
}

.private .mobile__navigation .profile-menu {
  background-color: #6c0062;
}
.private .mobile__navigation .profile-menu__user-name {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 2.2rem;
  line-height: 2.6rem;
  color: #fff;
}
.private .mobile__navigation .profile-menu__user-email {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #fff;
}
.private .mobile__navigation .profile-menu .header__navigation-profile {
  border-color: #fff;
  margin-right: 27px;
  height: 46px;
  width: 46px;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.public .header__navigation-list-item {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.public .header__navigation-list-item a {
  color: #000;
}
.public .header__logo {
  background-image: url("/assets/public/logo@3x.png");
  color: #37a995;
}
.public .header__text {
  color: #37a995;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.public .mobile__navigation-toggler span {
  background: #434343;
}
.public--fixed {
  background-color: #fff;
  border: 1px solid #eaeaea;
}
.public .go-top-icon {
  background-image: url("/assets/public/dropdown-active@3x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-color: white;
  border-color: #e0e0e0;
  border-radius: 50%;
  border-style: solid;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.auth .header__navigation-list-item {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.auth .header__navigation-list-item a {
  color: #000;
}
.auth .header__logo {
  background-image: url("/assets/public/logo@3x.png");
  color: #37a995;
}
.auth .header__text {
  color: #37a995;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.auth .header__navigation-profile {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/public/homepage/avatar-1-big@2x.png");
  border-color: #fff;
  border-style: solid;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.mixed {
  border-bottom: 1px solid #eaeaea;
}
.mixed .header__container .create-icon--events {
  background-image: url("/assets/public/create-event-icon@2x.png");
}
.mixed .header__container .create-icon--goals {
  background-image: url("/assets/public/create-goal-icon@2x.png");
}
.mixed .header__container .create-icon--wishlists {
  background-image: url("/assets/public/create-wishlist-icon@2x.png");
}
.mixed .header__navigation-list-item {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.mixed .header__navigation-list-item a {
  color: #000;
}
.mixed .header__navigation-list--active {
  font-weight: bold;
}
.mixed .header__logo {
  background-image: url("/assets/public/logo@3x.png");
  color: #37a995;
}
.mixed .header__text {
  color: #37a995;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.private {
  border-bottom: 1px solid #eaeaea;
}
.private .header__container .create-icon--events {
  background-image: url("/assets/public/create-event-icon@2x.png");
}
.private .header__container .create-icon--goals {
  background-image: url("/assets/public/create-goal-icon@2x.png");
}
.private .header__container .create-icon--wishlists {
  background-image: url("/assets/public/create-wishlist-icon@2x.png");
  padding-left: 34px !important;
}
.private .header__navigation-list-item {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  text-transform: uppercase;
}
.private .header__navigation-list-item a {
  color: #000;
  text-decoration: none;
}
.private .header__navigation-list-item .dropdown__content {
  background-color: #fff;
  text-transform: none;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.private .header__navigation-list-item .dropdown--active .dropdown__content {
  display: block;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.private .header__navigation-list-item button {
  text-transform: none;
}
.private .header__navigation-list-item-lava {
  background: #37a995;
}
.private .header__navigation-list-item:after {
  background: #37a995;
}
.private .header__logo {
  background-image: url("/assets/public/logo@3x.png");
  color: #37a995;
}
.private .header__navigation-profile {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/private/user_portrait.png");
  border-color: #434343;
  border-style: solid;
}
.private .header__navigation-profile:hover {
  border-color: #6b0061;
}

.achievement-meter__gauge-container .gauge {
  border-radius: 4px;
  background-color: rgba(181, 181, 181, 0.28);
}
.achievement-meter__gauge-container .gauge__bar {
  border-radius: 4px;
}
.achievement-meter__gauge-container .gauge__bar--1 {
  background-color: #62c692;
}
.achievement-meter__gauge-container .gauge__bar--2 {
  background-color: #308be1;
}
.achievement-meter__gauge-container .proportion {
  font-size: 2rem;
  line-height: 2.7rem;
  color: #434343;
}
.achievement-meter__gauge-container .proportion__collected {
  color: #62c692;
}
.achievement-meter__gauge-container .proportion__total {
  color: #000;
}
.achievement-meter .legend__name {
  font-size: 1.4rem;
  line-height: 2rem;
}
.achievement-meter .legend__name--1 {
  color: #62c692;
}
.achievement-meter .legend__name--1:before {
  border-radius: 5px;
  background-color: #62c692;
}
.achievement-meter .legend__name--2 {
  color: #308be1;
}
.achievement-meter .legend__name--2:before {
  border-radius: 5px;
  background-color: #308be1;
}
.achievement-meter--mobile .proportion {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}

.add-selected__button {
  border-radius: 6px;
  background-color: transparent;
  border-style: none;
}
.add-selected__button:focus {
  outline: none;
}
.add-selected__button--add {
  border-style: solid;
  border-color: #721067;
  color: #721067;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
}
.add-selected__button--remove {
  background-image: url("/assets/public/selection-wishlist@2x.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
}

.card-input {
  border: 1px solid #cacaca;
  border-right: none;
  border-left: none;
}
.card-input input {
  color: #37a894;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.card-input:last-of-type {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.card-input:last-of-type input {
  border-right: 1px solid #cacaca;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.card-input:first-of-type {
  border-left: 1px solid #cacaca;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
@media (max-width: 480px) {
  .card-input:first-of-type {
    border-radius: 1rem;
    border: 1px solid #cacaca;
  }
  .card-input:first-of-type + .card-input {
    border-left: 1px solid #cacaca;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
}
.card-input--error {
  border-color: #a94442;
}
.card-input--error .row .icon-area.icon-area {
  border-right: 1px solid #a94442;
}
.card-input--error:last-of-type {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-color: #a94442;
}
.card-input--error:last-of-type input {
  border-right: 1px solid #a94442;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-color: #a94442;
}
.card-input--error:first-of-type {
  border-left: 1px solid #a94442;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-color: #a94442;
}
.card-input .row .icon-area {
  border-right: 1px solid #cacaca;
}
.card-input .row .icon-area__icon {
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: contain;
}
.card-input .row .icon-area__icon--card {
  background-image: url("/assets/private/credit-card-icon@3x.png");
}

.credit-card__container span {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.credit-card__card-details {
  border-radius: 1rem;
}

.card-details {
  border-radius: 1rem;
  background-color: #f2f2f2;
}
.card-details span {
  font-size: 1.4rem;
}
.card-details .property {
  font-size: 1.4rem;
}
.card-details .property__label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
}
.card-details .property__value {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.card-details .property__type--visa {
  background-image: url("/assets/private/visa@2x.png");
}
.card-details .property__type--mastercard {
  background-image: url("/assets/private/mastercard@2x.png");
}
.card-details .property__type--cb {
  background-image: url("/assets/private/mastercard@2x.png");
}

.twitter-share-button {
  border-radius: 10px;
  background-color: #eaeaea;
  background-size: 14px auto;
  background-position: center;
  background-repeat: no-repeat;
  border-style: none;
  background-image: url("/assets/private/event-details/twitter-icon@3x.png");
  background-color: #00aaec;
}
.twitter-share-button:focus {
  outline: none;
}

.facebook-share-button {
  border-radius: 10px;
  background-image: url("/assets/private/event-details/facebook-icon@3x.png");
  background-color: #4460a0;
  background-size: 8px auto;
  background-position: center;
  background-repeat: no-repeat;
  border-style: none;
}
.facebook-share-button:focus {
  outline: none;
}

.password-input__circle {
  background: transparent;
  border-radius: 50%;
}
.password-input__circle--0 {
  background: #dedede;
}
.password-input__circle--0[data-score="4"] {
  background: #4caf50;
}
.password-input__circle--1 {
  background: #dedede;
}
.password-input__circle--1[data-score="3"] {
  background: #4caf50;
}
.password-input__circle--1[data-score="4"] {
  background: #4caf50;
}
.password-input__circle--2 {
  background: #dedede;
}
.password-input__circle--2[data-score="2"] {
  background: #ffeb3b;
}
.password-input__circle--2[data-score="3"] {
  background: #4caf50;
}
.password-input__circle--2[data-score="4"] {
  background: #4caf50;
}
.password-input__circle--3 {
  background: #dedede;
}
.password-input__circle--3[data-score="1"] {
  background: #f44336;
}
.password-input__circle--3[data-score="2"] {
  background: #ffeb3b;
}
.password-input__circle--3[data-score="3"] {
  background: #4caf50;
}
.password-input__circle--3[data-score="4"] {
  background: #4caf50;
}
.password-input__input {
  background-image: url("/assets/public/eye-hide@2x.png");
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: contain;
}
.password-input__password {
  background-image: url("/assets/public/eye-icon@2x.png");
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: contain;
}

.delete-confirmation__text {
  font-size: 1.4rem;
  line-height: 2rem;
}
.delete-confirmation__title {
  font-size: 2.2rem;
  line-height: 2.6rem;
  margin-bottom: 2.5rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.delete-confirmation .dropdown__content {
  background-color: #fff;
}
.delete-confirmation .dropdown__content .button {
  font-size: 1.2rem;
  line-height: 1.7rem;
}
.delete-confirmation.dropdown--active > .dropdown__content {
  display: block;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.delete-confirmation--mobile .delete-confirmation__text {
  color: #434343;
  font-size: 1.6rem;
  line-height: 2.2rem;
}

.region-warning-banner {
  background-color: #ff7b3b;
}
.region-warning-banner__stay-here {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.region-warning-banner__content {
  color: #fff;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.region-warning-banner__content a {
  color: #fff;
}

.browser-warning-banner {
  background-color: #ff7b3b;
}
.browser-warning-banner__stay-here {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.browser-warning-banner__content {
  color: #fff;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.browser-warning-banner__content a {
  color: #fff;
}
.browser-warning-banner__dismiss {
  background-color: #b2b2b2;
  color: black;
  opacity: 0.5;
}
.browser-warning-banner__dismiss:hover {
  opacity: 1;
}
.browser-warning-banner__icon--Chrome {
  background-image: url("/assets/public/not-supported/chrome@2x.png");
}
.browser-warning-banner__icon--IE {
  background-image: url("/assets/public/i.png");
}
.browser-warning-banner__icon--Edge {
  background-image: url("/assets/public/not-supported/edge@2x.png");
}
.browser-warning-banner__icon--Opera {
  background-image: url("/assets/public/not-supported/opera@2x.png");
}
.browser-warning-banner__icon--Firefox {
  background-image: url("/assets/public/not-supported/firefox@2x.png");
}
.browser-warning-banner__icon--Safari {
  background-image: url("/assets/public/not-supported/safari@2x.png");
}

.personal-info__modal p {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}

.maintenance__title {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  line-height: 5rem;
  font-size: 3.6rem;
}
.maintenance__detail {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-size: 2rem;
  line-height: 3rem;
}
.maintenance__image {
  background-image: url("/assets/public/maintenance@2x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.close-modal {
  background-image: url("/assets/public/close-modal-icon.svg");
  background-repeat: no-repeat;
  background-size: 90%;
}

.datepicker-dropdown .Select-menu-outer ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5rem;
}
.datepicker-dropdown .Select-menu-outer ::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  padding-right: 0.2rem;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
}
.datepicker-dropdown .Select-menu-outer ::-webkit-scrollbar-track {
  background-color: transparent;
}
.datepicker-dropdown--error .Select.Select--single.dropdown .Select-control, .datepicker-dropdown--error .Select.Select--single.dropdown.has-value .Select-control, .datepicker-dropdown--error .Select.Select--single.dropdown.has-value.is-pseudo-focused .Select-control {
  border: 1px solid #a94442;
  border-radius: 1rem;
}

.privacy p {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.privacy h1 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.privacy h2 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.privacy h3 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.privacy li {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.privacy a {
  color: #6b0061;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.cookies p {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.cookies p strong {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cookies h1 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cookies h2 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cookies h3 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cookies li {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.cookies a {
  color: #6b0061;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.tou p {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.tou h1 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.tou h2 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.tou h3 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.tou li {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.tou a {
  color: #6b0061;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.about-us__title {
  font-size: 3.8rem;
  line-height: 4.8rem;
  color: #6b0061;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
@media (min-width: 768px) {
  .about-us__title {
    font-size: 4.8rem;
    line-height: 5.8rem;
  }
}
.about-us__description {
  font-family: "Brandon Text Light", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-size: 2rem;
  line-height: 3rem;
}
@media (min-width: 768px) {
  .about-us__description {
    font-size: 3rem;
    line-height: 4.2rem;
  }
}
.about-us a:link, .about-us a:visited {
  color: #5d0055;
}

.info-box {
  display: flex;
  font-size: 2rem;
  line-height: 3.2rem;
}
.info-box .contact-us {
  background: linear-gradient(306.4deg, #441566 0%, #6e1365 100%);
  color: #fff;
}
.info-box .contact-us__title {
  font-size: 2.8rem;
  line-height: 3.6rem;
}
@media (min-width: 768px) {
  .info-box .contact-us__title {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}
.info-box .contact-us__location {
  height: 38px;
  background-position: center;
  background-image: url("/assets/public/homepage/location-icon@2x.png");
  background-repeat: no-repeat;
  background-size: 28px 38px;
}
@media (min-width: 768px) {
  .info-box .contact-us__location {
    width: 87px;
    background-position: left;
  }
}
.info-box .customer-support__img {
  height: 75px;
  background-position: center;
  background-size: 87px 75px;
  background-image: url("/assets/public/homepage/customers-support@2x.png");
  background-repeat: no-repeat;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .info-box .customer-support__img {
    width: 87px;
    background-position: left;
  }
}
.info-box .customer-support__title {
  color: #6b0061;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.info-box .media-inquired {
  color: #434343;
}
.info-box .media-inquired__img {
  height: 70px;
  background-position: center;
  background-size: 57px 70px;
  background-image: url("/assets/public/homepage/media-inquires@2x.png");
  background-repeat: no-repeat;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .info-box .media-inquired__img {
    width: 57px;
    background-position: left;
  }
}
.info-box .media-inquired__title {
  color: #6b0061;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}

.social {
  border-top-color: #eee;
  border-bottom-color: #eee;
}
.social__facebook {
  border-left-color: #eee;
  background: url("/assets/public/homepage/facebook-inactive.png") no-repeat center;
}
.social__facebook:hover {
  background: url("/assets/public/homepage/hover-facebook.png") no-repeat center;
}
.social__instagram {
  border-left-color: #eee;
  background: url("/assets/public/homepage/instagram-inactive.png") no-repeat center;
}
.social__instagram:hover {
  background: url("/assets/public/homepage/hover-instagram.png") no-repeat center;
}
.social__twitter {
  border-left-color: #eee;
  background: url("/assets/public/homepage/twitter-inactive.png") no-repeat center;
}
.social__twitter:hover {
  background: url("/assets/public/homepage/hover-twitter.png") no-repeat center;
}
.social__snapchat {
  border-left-color: #eee;
  border-right-color: #eee;
  background: url("/assets/public/homepage/snapchat-inactive.png") no-repeat center;
}
.social__snapchat:hover {
  background: url("/assets/public/homepage/hover-snapchat.png") no-repeat center;
}

.snapchat__tooltip {
  border-color: #eee;
}

.home-page .features {
  background-color: #f7f7f7;
  border-color: #eee;
  border-style: solid;
}
@media (min-width: 1200px) {
  .home-page .features {
    background-color: transparent;
  }
}

.home-page {
  font-family: "Brandon Text Light", Arial, Helvetica, Verdana, sans-serif;
  font-size: 3rem;
  color: #434343;
}
.home-page h2 {
  font-size: 2.8rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  line-height: 3.8rem;
}
@media (min-width: 1200px) {
  .home-page h2 {
    font-size: 3.6rem;
  }
}
.home-page h3 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.4rem;
  color: #434343;
}
.home-page__info h1 {
  color: #6b0061;
  font-size: 2.6rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  line-height: 3.6rem;
}
@media (min-width: 1200px) {
  .home-page__info h1 {
    font-size: 4.8rem;
    line-height: 5.6rem;
    font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
    font-weight: bold;
  }
}
.home-page .info__circle--1 {
  background-image: url("/assets/public/homepage/circle-1.png");
}
.home-page .info__circle--2 {
  background-image: url("/assets/public/homepage/circle-2.png");
}
.home-page .info__circle--3 {
  background-image: url("/assets/public/homepage/circle-3.png");
}
.home-page .info__circle--4 {
  background-image: url("/assets/public/homepage/circle-4.png");
}
.home-page .info__circle--5 {
  background-image: url("/assets/public/homepage/circle-5.png");
}
.home-page .info__circle--6 {
  background-image: url("/assets/public/homepage/circle-6.png");
}
.home-page .info__download {
  display: flex;
  justify-content: center;
  margin-top: 44px;
}
.home-page .info__apple-link button {
  border-radius: 10px;
  background-image: url("/assets/public/homepage/ios-button@2x.png");
  background-repeat: no-repeat;
  background-size: 117px 40px;
}
@media (min-width: 1200px) {
  .home-page .info__apple-link button {
    background-size: 150px 51px;
  }
}
.home-page .info__gplay-link button {
  border-radius: 10px;
  background-image: url("/assets/public/homepage/google-button@2x.png");
  background-repeat: no-repeat;
  background-size: 117px 40px;
}
@media (min-width: 1200px) {
  .home-page .info__gplay-link button {
    background-size: 150px 51px;
  }
}
.home-page .info__devices {
  background-image: url("/assets/public/homepage/devices@3x.png");
  background-repeat: no-repeat;
  background-position: center 30px;
  background-size: cover;
}
@media (min-width: 1200px) {
  .home-page .info__devices {
    background-size: 100% auto;
    background-position: center -90px;
  }
}
.home-page__about {
  font-size: 1.8rem;
  font-weight: 500;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  line-height: 3.2rem;
}
@media (min-width: 1200px) {
  .home-page__about {
    font-size: 3rem;
    line-height: 4.2rem;
  }
}
.home-page__experience .experience__imac {
  background-image: url("/assets/public/homepage/imac@2x.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.home-page__experience .experience__description .description__title {
  font-size: 2.8rem;
  line-height: 4.8rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
@media (min-width: 1200px) {
  .home-page__experience .experience__description .description__title {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }
}
.home-page__experience .experience__description .description__text {
  font-size: 1.8rem;
  line-height: 3rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  letter-spacing: -0.4px;
}
@media (min-width: 1200px) {
  .home-page__experience .experience__description .description__text {
    font-size: 2rem;
    line-height: 3.2rem;
  }
}
.home-page__experience .experience__description .description__button {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
@media (min-width: 1200px) {
  .home-page__experience .experience__description .description__button {
    font-size: 2rem;
    line-height: 2.7rem;
  }
}
.home-page__payments .payments__title {
  font-size: 1.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  text-transform: uppercase;
  line-height: 4.8rem;
  color: #434343;
  margin: 0 0 2.2rem;
}
@media (min-width: 1200px) {
  .home-page__payments .payments__title {
    margin: 0 0 6.3rem;
  }
}
.home-page__payments .payments__partner-list .partner-list__partner {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.home-page__payments .payments__partner-list .partner-list__partner--visa {
  background-image: url("/assets/public/homepage/visa@2x.png");
}
.home-page__payments .payments__partner-list .partner-list__partner--mastercard {
  background-image: url("/assets/public/homepage/mastercard@2x.png");
}
.home-page__payments .payments__partner-list .partner-list__partner--american-express {
  background-image: url("/assets/public/homepage/american@2x.png");
}
.home-page__payments .payments__partner-list .partner-list__partner--discover {
  background-image: url("/assets/public/homepage/discover@2x.png");
}
.home-page__payments .payments__partner-list .partner-list__partner--jcb {
  background-image: url("/assets/public/homepage/jcb@2x.png");
}
.home-page__payments .payments__partner-list .partner-list__partner--diners {
  background-image: url("/assets/public/homepage/diners@2x.png");
}
.home-page__get-app {
  text-align: center;
}
@media (min-width: 1200px) {
  .home-page__get-app {
    background-image: url("/assets/public/homepage/apps-section@2x.png");
    background-size: cover;
    background-position: center;
    text-align: left;
  }
}
.home-page__get-app .get-app__title {
  font-size: 2.8rem;
  line-height: 4.8rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
@media (min-width: 1200px) {
  .home-page__get-app .get-app__title {
    font-size: 3.6rem;
    line-height: 4.8rem;
    color: #fff;
  }
}
.home-page__get-app .get-app__text {
  font-size: 1.8rem;
  line-height: 3rem;
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
@media (min-width: 1200px) {
  .home-page__get-app .get-app__text {
    font-size: 2rem;
    line-height: 3.2rem;
    color: #fff;
  }
}
.home-page__get-app .get-app__image {
  background-image: url("/assets/public/homepage/apps-section@2x.png");
  background-size: cover;
  background-position: center;
  height: 20.4rem;
  margin-bottom: 4.5rem;
}
@media (min-width: 1200px) {
  .home-page__get-app .get-app__image {
    display: none;
  }
}
.home-page__get-app .get-app__apple-link button {
  border-radius: 10px;
  background-image: url("/assets/public/homepage/ios-button@2x.png");
  background-repeat: no-repeat;
  background-size: 117px 40px;
}
@media (min-width: 1200px) {
  .home-page__get-app .get-app__apple-link button {
    background-size: 150px 51px;
  }
}
.home-page__get-app .get-app__gplay-link button {
  border-radius: 10px;
  background-image: url("/assets/public/homepage/google-button@2x.png");
  background-repeat: no-repeat;
  background-size: 117px 40px;
}
@media (min-width: 1200px) {
  .home-page__get-app .get-app__gplay-link button {
    background-size: 150px 51px;
  }
}

.dont-miss__title {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 2.8rem;
}
@media (min-width: 768px) {
  .dont-miss__title {
    font-size: 3.6rem;
  }
}
.dont-miss__description {
  color: #434343;
  font-size: 1.6rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
@media (min-width: 768px) {
  .dont-miss__description {
    font-size: 2rem;
  }
}

.enter-address__email {
  line-height: 6.6rem;
  border-radius: 50px;
  box-shadow: 3px 2px 13px rgba(0, 0, 0, 0.1) !important;
  font-size: 1.8rem;
  border-color: transparent;
}
.enter-address__email:hover {
  border-color: #37a995;
}
.enter-address__submit {
  background-image: url("/assets/public/subscribe-arrow.png");
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
}

.not-found__image {
  background-image: url("/assets/public/404@2x.png");
}
.not-found__message {
  line-height: 5.5rem;
  font-size: 3.6rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}

.not-supported__image {
  background-image: url("/assets/public/not-supported/not-supported@2x.png");
}
.not-supported__title {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-size: 3.6rem;
  line-height: 50px;
  text-align: center;
}
.not-supported__message {
  font-size: 2rem;
  color: #434343;
  line-height: 30px;
  text-align: center;
}
.not-supported__separator {
  color: #e8e8e8;
}
.not-supported__download__info {
  font-size: 2rem;
  color: #434343;
  line-height: 30px;
  text-align: center;
}
.not-supported__download__chrome {
  background-image: url("/assets/public/not-supported/chrome@2x.png");
}
.not-supported__download__firefox {
  background-image: url("/assets/public/not-supported/firefox@2x.png");
}
.not-supported__download__safari {
  background-image: url("/assets/public/not-supported/safari@2x.png");
}
.not-supported__download__edge {
  background-image: url("/assets/public/not-supported/edge@2x.png");
}
.not-supported__download__opera {
  background-image: url("/assets/public/not-supported/opera@2x.png");
}

.selector__container {
  background-color: #efefef;
}

.select-button-inner--active {
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
}
.select-button-inner__image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: none;
}
@media (min-width: 1200px) {
  .select-button-inner__image {
    display: block;
  }
}
.select-button-inner__image.events {
  background-image: url("/assets/public/homepage/events-icon@2x.png");
}
.select-button-inner__image.goals {
  background-image: url("/assets/public/homepage/goal-icon@2x.png");
}
.select-button-inner__image.wishlists {
  background-image: url("/assets/public/homepage/wishlist-icon@2x.png");
}
.select-button-inner__text {
  font-size: 1.4rem;
  color: #6b0061;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  .select-button-inner__text {
    font-size: 1.6rem;
  }
}

@media (min-width: 1200px) {
  .selection__phone {
    background-image: url("/assets/public/homepage/iphone@2x.png");
    background-size: 100%;
  }
}

.feature .description__title {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 4rem;
}
@media (min-width: 1200px) {
  .feature .description__title {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }
}
.feature .description__text {
  font-size: 1.8rem;
  line-height: 3rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
@media (min-width: 1200px) {
  .feature .description__text {
    font-size: 2rem;
    line-height: 3.2rem;
  }
}

.events .feature__panel--one {
  background-image: url("/assets/public/homepage/events-1@2x.png");
  background-size: cover;
  background-position: center;
}
.events .feature__panel--two {
  background-image: url("/assets/public/homepage/events-2@2x.png");
  background-size: cover;
  background-position: center;
}
.events .feature__panel--three {
  background-image: url("/assets/public/homepage/events-3@2x.png");
  background-size: cover;
  background-position: center;
}
.events .feature__panel--four {
  background-image: url("/assets/public/homepage/events-4@2x.png");
  background-size: cover;
  background-position: center;
}
.events .feature__panel--mobile {
  background-image: url("/assets/public/homepage/events-full.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.goals .feature__panel--one {
  background-image: url("/assets/public/homepage/goals-1@2x.png");
  background-size: cover;
  background-position: center;
}
.goals .feature__panel--two {
  background-image: url("/assets/public/homepage/goals-2@2x.png");
  background-size: cover;
  background-position: center;
}
.goals .feature__panel--three {
  background-image: url("/assets/public/homepage/goals-3@2x.png");
  background-size: cover;
  background-position: center;
}
.goals .feature__panel--four {
  background-image: url("/assets/public/homepage/goals-4@2x.png");
  background-size: cover;
  background-position: center;
}
.goals .feature__panel--mobile {
  background-image: url("/assets/public/homepage/goals-full.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.wishlists .feature__panel--one {
  background-image: url("/assets/public/homepage/wishlist-1@2x.png");
  background-size: cover;
  background-position: center;
}
.wishlists .feature__panel--two {
  background-image: url("/assets/public/homepage/wishlist-2@2x.png");
  background-size: cover;
  background-position: center;
}
.wishlists .feature__panel--three {
  background-image: url("/assets/public/homepage/wishlist-3@2x.png");
  background-size: cover;
  background-position: center;
}
.wishlists .feature__panel--four {
  background-image: url("/assets/public/homepage/wishlist-4@2x.png");
  background-size: cover;
  background-position: center;
}
.wishlists .feature__panel--mobile {
  background-image: url("/assets/public/homepage/wishlist-full.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.testimonials {
  background: linear-gradient(306.39deg, #b81f63 0%, #f91f62 100%);
}
.testimonials:before {
  background: linear-gradient(to left bottom, #ffffff 50%, transparent 50%);
}
.testimonials__title {
  color: #fff;
  font-size: 2.8rem;
  line-height: 4.8rem;
}
@media (min-width: 1200px) {
  .testimonials__title {
    font-size: 3.8rem;
    line-height: 5.8rem;
  }
}
.testimonials__circle {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.testimonials__circle--1 {
  background-image: url("/assets/public/homepage/2-circle-1@2x.png");
}
.testimonials__circle--2 {
  background-image: url("/assets/public/homepage/2-circle-2@2x.png");
}
.testimonials__circle--3 {
  background-image: url("/assets/public/homepage/2-circle-2@2x.png");
}
.testimonials__circle--4 {
  background-image: url("/assets/public/homepage/2-circle-3@2x.png");
}
.testimonials__circle--5 {
  background-image: url("/assets/public/homepage/2-circle-4@2x.png");
}
.testimonials__circle--6 {
  background-image: url("/assets/public/homepage/2-circle-5@2x.png");
}
.testimonials__circle--7 {
  background-image: url("/assets/public/homepage/2-circle-5@2x.png");
}
.testimonials__circle--8 {
  background-image: url("/assets/public/homepage/2-circle-6@2x.png");
}
.testimonials__circle--9 {
  background-image: url("/assets/public/homepage/2-circle-7@2x.png");
}
.testimonials__circle--10 {
  background-image: url("/assets/public/homepage/2-circle-8@2x.png");
}

.carousel__person .person__photo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.carousel__person .person__photo--1 {
  background-image: url("/assets/public/homepage/avatar-1-big@2x.png");
}
.carousel__person .person__photo--2 {
  background-image: url("/assets/public/homepage/avatar-2-big@2x.png");
}
.carousel__person .person__photo--3 {
  background-image: url("/assets/public/homepage/avatar-3-big@2x.png");
}
.carousel__person .person__photo--4 {
  background-image: url("/assets/public/homepage/avatar-4-big@2x.png");
}
.carousel__person .person__photo--5 {
  background-image: url("/assets/public/homepage/avatar-5-big@2x.png");
}
.carousel__person .person__photo--6 {
  background-image: url("/assets/public/homepage/avatar-6-big@2x.png");
}
.carousel__person .person__name {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2.7rem;
  color: #fff;
}
.carousel__person .person__text {
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: -0.45px;
  color: #fff;
}
@media (min-width: 1200px) {
  .carousel__person .person__text {
    font-size: 2rem;
    line-height: 2.7rem;
  }
}
.carousel__control {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: transparent;
}
.carousel__control--prev {
  background-image: url("/assets/public/homepage/arrow-slider-left@2x.png");
}
.carousel__control--next {
  background-image: url("/assets/public/homepage/arrow-slider-right@2x.png");
}

.select-person__person {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.select-person__person--1 {
  background-image: url("/assets/public/homepage/avatar-1-big@2x.png");
}
.select-person__person--2 {
  background-image: url("/assets/public/homepage/avatar-2-big@2x.png");
}
.select-person__person--3 {
  background-image: url("/assets/public/homepage/avatar-3-big@2x.png");
}
.select-person__person--4 {
  background-image: url("/assets/public/homepage/avatar-4-big@2x.png");
}
.select-person__person--5 {
  background-image: url("/assets/public/homepage/avatar-5-big@2x.png");
}
.select-person__person--6 {
  background-image: url("/assets/public/homepage/avatar-6-big@2x.png");
}

.left-container__illustration {
  background-image: url("/assets/public/login-illustration.png");
}

.right-container h1 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.right-container label {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.right-container a {
  color: #6b0061;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}

.fp-modal h2 {
  color: #037b99;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.fp-modal p {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.fp-modal label {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}

.left-container__illustration {
  background-image: url("/assets/public/login-illustration.png");
}

.right-container h1 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.right-container label {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.right-container a {
  color: #6b0061;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}

.reset-password__title {
  font-size: 3rem;
  line-height: 4.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #000;
}
.reset-password__text {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #434343;
}
.reset-password__label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}

.email-verification__image {
  width: 80px;
  height: 80px;
  background-image: url("/assets/public/email-illustration@2x.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.email-verification__title {
  font-size: 3rem;
  line-height: 4.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.email-verification__text {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #434343;
}
.email-verification__send-link {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #6c0062;
  background: none;
  border: none;
}

.email-verification-confirmation__image {
  width: 80px;
  height: 80px;
  background-image: url("/assets/public/email-illustration@2x.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.email-verification-confirmation__title {
  font-size: 3rem;
  line-height: 4.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.email-verification-confirmation__text {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #434343;
}
.email-verification-confirmation__send-link {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #6c0062;
  background: none;
  border: none;
}

.engagement-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.engagement-container .engagement-notifications__section h2 {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
}
.engagement-container .engagement-notifications__section label {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  text-transform: none;
}

.events-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-size: 3rem;
  line-height: 3rem;
}
.events-container--mobile h1 {
  font-size: 2.4rem;
}

.create-event__field-label {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.create-event__field-label--light {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.create-event__error-label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #d0021b;
}
.create-event__disclaimer {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.create-event__wishlist-links {
  color: #6c0062;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.create-event__wishlist-links__label {
  color: #434343;
}
.create-event__wishlist-links__wrapper {
  background-color: #f2f2f2;
  border-color: #cacaca;
}

.events-list__container--mobile .page-title {
  font-size: 2.8rem;
  line-height: 4.8rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.events-list__container--mobile .toggle {
  padding: 13px;
  background-color: #eaeaea;
  border-radius: 10px;
  width: 40px;
  height: 40px;
}
.events-list__container--mobile .toggle__icon {
  background-image: url("/assets/private/event-details/filter-icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 15px;
  display: block;
  height: 15px;
}
.events-list__container--mobile .toggle__icon--close {
  background-image: url("/assets/public/close-modal-icon.svg");
}
.events-list__selector-container .selector__label {
  color: #434343;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
}

.events-list-item {
  border: 1px solid #e6e6e6;
  background-color: #fff;
}
.events-list-item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.events-list-item__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.events-list-item__image--baby {
  background-image: url("/assets/public/events/babyshower.svg");
}
.events-list-item__image--bachelor {
  background-image: url("/assets/public/events/bachelor.svg");
}
.events-list-item__image--birthday {
  background-image: url("/assets/public/events/birthday.svg");
}
.events-list-item__image--holiday {
  background-image: url("/assets/public/events/holiday.svg");
}
.events-list-item__image--vacation {
  background-image: url("/assets/public/events/vacation.svg");
}
.events-list-item__image--wedding {
  background-image: url("/assets/public/events/wedding.svg");
}
.events-list-item__image--custom {
  background-image: url("/assets/public/events/custom1.svg");
}
.events-list-item__image--farewell {
  background-image: url("/assets/public/events/farewell.svg");
}
.events-list-item__image--house-warming {
  background-image: url("/assets/public/events/housewarming.svg");
}
.events-list-item__image--internal {
  border-style: solid;
  border-color: #e0e0e0;
}
.events-list-item .details__title {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.events-list-item .details__title span {
  text-transform: capitalize;
}
.events-list-item .details__status {
  font-size: 1rem;
  line-height: 1.4rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  background-color: #eaeaea;
  color: #434343;
}
.events-list-item .details .actions__button {
  background-color: #eaeaea;
  background-size: 14px auto;
  background-position: center;
  background-repeat: no-repeat;
  border-style: none;
  opacity: 0.8;
  transition: 0.2s ease-out;
}
.events-list-item .details .actions__button:hover {
  opacity: 1;
}
.events-list-item .details .actions__button--edit {
  background-image: url("/assets/public/edit-icon@2x.png");
}
.events-list-item .details .actions__button--delete {
  background-image: url("/assets/public/delete-icon@2x.png");
}
.events-list-item .details .gift__label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-list-item .details .gift__value {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-list-item .details .gift a {
  color: #6b0061;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.events-list-item .details .gift__link a {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.events-list-item .details .gift__requested {
  color: #434343;
  font-size: 1.6rem;
  line-height: 22px;
}
.events-list-item .details .contributions__label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-list-item .details .contributions__value {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-list-item .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.events-list-item .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.events-list-item .details__content .property__value.occasion {
  text-transform: capitalize;
}
.events-list-item .dropdown .button--trigger {
  background-color: #eaeaea;
  border-right-color: #b2b2b2;
  border-style: solid;
}
.events-list-item .dropdown .button--trigger span {
  color: #b2b2b2;
}
.events-list-item .dropdown .button--trigger:hover span {
  margin: 0 0.5px;
  color: #810075;
}
.events-list-item .dropdown--active .button--trigger span {
  margin: 0 0.5px;
  color: #810075;
}
.events-list-item .dropdown__content {
  background-color: #fff;
}
.events-list-item .dropdown__content .action-link {
  font-size: 14px;
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  cursor: pointer;
}
.events-list-item .dropdown__content .action-link:hover {
  color: #580c79;
}
.events-list-item .dropdown__content .delete-link {
  color: #6c0062;
}
.events-list-item .dropdown--active > .dropdown__content {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.event-tabs__tab-list {
  display: flex;
  border-style: solid;
  border-color: #e0e0e0;
}
.event-tabs__tab {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
  background-color: #eaeaea;
  cursor: pointer;
  border-style: solid;
  border-color: #e0e0e0;
}
.event-tabs__tab-wrapper {
  border-style: solid;
  border-color: #e0e0e0;
}

.react-tabs__tab--selected {
  color: #6c0062;
  background-color: #fff;
  border-color: #fff;
}

.spacer {
  display: block;
  height: 1px;
  border: 0;
  background-color: #eaeaea;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.event-tabs__wrapper--mobile .event-tabs__tab {
  border-top-width: 1px;
}
.event-tabs__wrapper--mobile .event-tabs__tab-list {
  border: none;
}
.event-tabs__wrapper--mobile .event-tabs__tab-wrapper {
  border: none;
}
.event-tabs__wrapper--mobile .event-tabs__tab-wrapper .comments__container .comments__filters-wrapper {
  border-color: #cacaca;
  border-bottom-width: 1px;
  border-style: solid;
}
.event-tabs__wrapper--mobile .event-tabs__tab-wrapper .comments__container .submit-comment {
  border: none;
}
.event-tabs__wrapper--mobile .event-tabs__tab-wrapper .comments__container .submit-comment::after {
  background: #f2f2f2;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
  border-color: #cacaca;
}
.event-tabs__wrapper--mobile .event-tabs__tab-wrapper .comments__container .submit-comment__wrapper {
  border: 1px solid #cacaca;
  background-color: #fff;
}
.event-tabs__wrapper--mobile .react-tabs__tab--selected {
  color: #6c0062;
  background-color: #fff;
  border-color: #e0e0e0;
}

.events-details-item__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.events-details-item__image--baby {
  background-image: url("/assets/public/events/babyshower.svg");
}
.events-details-item__image--bachelor {
  background-image: url("/assets/public/events/bachelor.svg");
}
.events-details-item__image--birthday {
  background-image: url("/assets/public/events/birthday.svg");
}
.events-details-item__image--holiday {
  background-image: url("/assets/public/events/holiday.svg");
}
.events-details-item__image--vacation {
  background-image: url("/assets/public/events/vacation.svg");
}
.events-details-item__image--wedding {
  background-image: url("/assets/public/events/wedding.svg");
}
.events-details-item__image--custom {
  background-image: url("/assets/public/events/custom1.svg");
}
.events-details-item__image--farewell {
  background-image: url("/assets/public/events/farewell.svg");
}
.events-details-item__image--house-warming {
  background-image: url("/assets/public/events/housewarming.svg");
}
.events-details-item__image--internal {
  border-style: solid;
  border-color: #e0e0e0;
}
.events-details-item .details__title {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.events-details-item .details__title span {
  text-transform: capitalize;
}
.events-details-item .details__status {
  font-size: 1rem;
  line-height: 1.4rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  background-color: #eaeaea;
  color: #434343;
}
.events-details-item .details .description__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.events-details-item .details .description__value {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-details-item .details .description__links a {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.events-details-item .details .description__links a:hover {
  color: #6c0062;
}
.events-details-item .details .description__links__label {
  color: #434343;
}
.events-details-item .details .description__links__view-more {
  color: #6c0062;
}
.events-details-item .details .actions__button {
  background-color: #eaeaea;
  background-size: 14px auto;
  background-position: center;
  background-repeat: no-repeat;
  border-style: none;
  opacity: 0.8;
  transition: 0.2s ease-out;
}
.events-details-item .details .actions__button:hover {
  opacity: 1;
}
.events-details-item .details .actions__button--edit {
  background-image: url("/assets/public/edit-icon@2x.png");
}
.events-details-item .details .actions__button--delete {
  background-image: url("/assets/public/delete-icon@2x.png");
}
.events-details-item .details .gift__label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-details-item .details .gift__value {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-details-item .details .gift a {
  color: #6b0061;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.events-details-item .details .gift__link a {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.events-details-item .details .gift__requested {
  color: #434343;
  font-size: 1.6rem;
  line-height: 22px;
}
.events-details-item .details .contributions__label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-details-item .details .contributions__value {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-details-item .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.events-details-item .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.events-details-item .details__content .property__value.occasion {
  text-transform: capitalize;
}

.events-details-item-mobile__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.events-details-item-mobile__image--baby {
  background-image: url("/assets/public/events/babyshower.svg");
}
.events-details-item-mobile__image--bachelor {
  background-image: url("/assets/public/events/bachelor.svg");
}
.events-details-item-mobile__image--birthday {
  background-image: url("/assets/public/events/birthday.svg");
}
.events-details-item-mobile__image--holiday {
  background-image: url("/assets/public/events/holiday.svg");
}
.events-details-item-mobile__image--vacation {
  background-image: url("/assets/public/events/vacation.svg");
}
.events-details-item-mobile__image--wedding {
  background-image: url("/assets/public/events/wedding.svg");
}
.events-details-item-mobile__image--custom {
  background-image: url("/assets/public/events/custom1.svg");
}
.events-details-item-mobile__image--farewell {
  background-image: url("/assets/public/events/farewell.svg");
}
.events-details-item-mobile__image--house-warming {
  background-image: url("/assets/public/events/housewarming.svg");
}
.events-details-item-mobile__image--internal {
  border-style: solid;
  border-color: #e0e0e0;
}
.events-details-item-mobile .details__title {
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.events-details-item-mobile .details__title span {
  text-transform: capitalize;
}
.events-details-item-mobile .details__status {
  font-size: 1rem;
  line-height: 1.4rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  background-color: #eaeaea;
  color: #434343;
}
.events-details-item-mobile .details .description__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.events-details-item-mobile .details .description__value {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-details-item-mobile .details .description__links a {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.events-details-item-mobile .details .description__links a:hover {
  color: #6c0062;
}
.events-details-item-mobile .details .description__links__label {
  color: #434343;
}
.events-details-item-mobile .details .description__links__view-more {
  color: #6c0062;
}
.events-details-item-mobile .details .actions {
  background-color: #f2f2f2;
}
.events-details-item-mobile .details .actions::after {
  background-color: #f2f2f2;
}
.events-details-item-mobile .details .actions__button {
  background-color: #fff;
  background-size: 14px auto;
  background-position: center;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  opacity: 0.8;
  border-color: #e6e6e6;
  transition: 0.2s ease-out;
}
.events-details-item-mobile .details .actions__button:hover {
  opacity: 1;
}
.events-details-item-mobile .details .actions__button--edit {
  background-image: url("/assets/public/edit-icon@2x.png");
}
.events-details-item-mobile .details .actions__button--delete {
  background-image: url("/assets/public/delete-icon@2x.png");
}
.events-details-item-mobile .details .gift__label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-details-item-mobile .details .gift__value {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-details-item-mobile .details .gift a {
  color: #6b0061;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.events-details-item-mobile .details .gift__link a {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.events-details-item-mobile .details .gift__requested {
  color: #434343;
  font-size: 1.6rem;
  line-height: 22px;
}
.events-details-item-mobile .details .contributions__label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-details-item-mobile .details .contributions__value {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 22px;
  color: #434343;
}
.events-details-item-mobile .details__content {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.events-details-item-mobile .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.events-details-item-mobile .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.events-details-item-mobile .details__content .property__value.occasion {
  text-transform: capitalize;
}

.invite-friends h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.invite-friends h2 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.invite-friends h3 {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.invite-friends label {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-weight: 500;
}
.invite-friends .participants__container {
  border: 1px solid #e6e6e6;
}
.invite-friends .participants__author {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}

.request-modal h2 {
  color: #037b99;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.request-modal p {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.request-modal label {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}

.comments__filters-wrapper {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.comments__filter {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
  background: transparent;
  border: none;
}
.comments__filter--active {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.comments__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comments__write {
  background: transparent;
  border: none;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #6c0062;
  text-decoration: underline;
  text-transform: lowercase;
}

.comment__image {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.comment__image--default {
  background-size: cover;
  background-image: url("/assets/private/user_portrait.png");
  background-position: bottom center;
  border-style: solid;
  border-color: #e0e0e0;
}
.comment__image--close {
  background-size: cover;
  background-image: url("/assets/private/remove-photo-icon@2x.png");
}
.comment__body {
  border: 1px solid #e0e0e0;
  background-color: #fafafa;
  position: relative;
}
.comment__body:before, .comment__body:after {
  right: 100%;
  top: 24px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.comment__body:before {
  border-color: transparent;
  border-right-color: #d0d0d0;
  border-width: 12px;
  margin-top: -12px;
}
.comment__body:after {
  border-color: transparent;
  border-right-color: #fafafa;
  border-width: 11px;
  margin-top: -11px;
}
.comment__author {
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.comment__message {
  font-size: 1.4rem;
  line-height: 2rem;
}
.comment__action {
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #6c0062;
}
.comment__action:focus {
  outline: none;
}
.comment .delete-comment__text {
  font-size: 1.4rem;
  line-height: 2rem;
}
.comment .delete-comment__actions .button {
  font-size: 1.4rem;
  line-height: 2rem;
}

.meine.comment .comment__body {
  color: #fff;
  background-color: #37a995;
  border: none;
}
.meine.comment .comment__body:after, .meine.comment .comment__body:before {
  left: 100%;
}
.meine.comment .comment__body:after {
  border-color: transparent;
  border-left-color: #37a995;
  border-width: 12px;
  margin-top: -12px;
}
.meine.comment .comment__body:before {
  border-color: transparent;
  border-left-color: #37a995;
  border-width: 12px;
  margin-top: -12px;
}

.participants__image {
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.participants__image--default {
  background-position: bottom center;
  border-style: solid;
  border-color: #e0e0e0;
}
.participants__image--default-1 {
  background-size: auto 75%;
  background-image: url("/assets/private/anonymous-icon@2x.png");
}
.participants__image--default-2 {
  background-size: contain;
  background-image: url("/assets/private/avatar-1@2x.png");
}
.participants__image--default-3 {
  background-size: contain;
  background-image: url("/assets/private/avatar-2@2x.png");
}
.participants__image--default-4 {
  background-size: contain;
  background-image: url("/assets/private/avatar-3@2x.png");
}
.participants__image--default-5 {
  background-size: contain;
  background-image: url("/assets/private/avatar-4@2x.png");
}
.participants__image--default-6 {
  background-size: contain;
  background-image: url("/assets/private/avatar-5@2x.png");
}
.participants__image--default-7 {
  background-size: contain;
  background-image: url("/assets/private/avatar-6@2x.png");
}
.participants__author {
  font-size: 1.4rem;
  line-height: 1.7rem;
}
.participants__message {
  font-size: 1.2rem;
  line-height: 1.7rem;
}
.participants__action {
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #6c0062;
}
.participants__action:focus {
  outline: none;
}
.participants__via-email {
  background-image: url("/assets/private/email-icon@2x.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.participants__via-phone {
  background-image: url("/assets/private/phone-icon@2x.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.eventDetails_participants__filters-wrapper {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.eventDetails_participants__filter {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
  background: transparent;
  border: none;
}
.eventDetails_participants__filter--active {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.eventDetails_participants__image {
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.eventDetails_participants__image--default {
  background-position: bottom center;
  border-style: solid;
  border-color: #e0e0e0;
}
.eventDetails_participants__image--default-1 {
  background-size: auto 75%;
  background-image: url("/assets/private/anonymous-icon@2x.png");
}
.eventDetails_participants__image--default-2 {
  background-size: contain;
  background-image: url("/assets/private/avatar-1@2x.png");
}
.eventDetails_participants__image--default-3 {
  background-size: contain;
  background-image: url("/assets/private/avatar-2@2x.png");
}
.eventDetails_participants__image--default-4 {
  background-size: contain;
  background-image: url("/assets/private/avatar-3@2x.png");
}
.eventDetails_participants__image--default-5 {
  background-size: contain;
  background-image: url("/assets/private/avatar-4@2x.png");
}
.eventDetails_participants__image--default-6 {
  background-size: contain;
  background-image: url("/assets/private/avatar-5@2x.png");
}
.eventDetails_participants__image--default-7 {
  background-size: contain;
  background-image: url("/assets/private/avatar-6@2x.png");
}
.eventDetails_participants__image-wrapper .contributed-amount {
  color: #fff;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.2rem;
  line-height: 1.7rem;
  background-color: #62c692;
}
.eventDetails_participants__author {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.eventDetails_participants__joined {
  color: #9a9a9a;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.2rem;
  line-height: 1.7rem;
}

.pending-invitations h2 {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  letter-spacing: -0.8px;
}
.pending-invitations p {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.pending-invitations__list-Wrapper {
  border: 1px solid #f2f2f2;
}
.pending-invitations__list-Wrapper li {
  border-bottom: 1px solid #f2f2f2;
}
.pending-invitations__list-Wrapper li .pending-invitations__wrapper .pending-invitations__body .pending-invitations__user-name {
  color: #037b99;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.pending-invitations__list-Wrapper li .pending-invitations__wrapper .pending-invitations__body .property__label {
  color: #9a9a9a;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.pending-invitations__list-Wrapper li .pending-invitations__wrapper .pending-invitations__body .property__value {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.pending-invitations__list-Wrapper li .pending-invitations__invited-by {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  background-color: #eaeaea;
  color: #434343;
}
.pending-invitations__disclaimer {
  border: 1px solid #f2f2f2;
}
.pending-invitations ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5rem;
}
.pending-invitations ::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  padding-right: 0.2rem;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
}
.pending-invitations ::-webkit-scrollbar-track {
  background-color: transparent;
}

.contributors__image {
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.contributors__image--default {
  background-position: bottom center;
  border-style: solid;
  border-color: #e0e0e0;
}
.contributors__image--default-1 {
  background-size: auto 75%;
  background-image: url("/assets/private/anonymous-icon@2x.png");
}
.contributors__image--default-2 {
  background-size: contain;
  background-image: url("/assets/private/avatar-1@2x.png");
}
.contributors__image--default-3 {
  background-size: contain;
  background-image: url("/assets/private/avatar-2@2x.png");
}
.contributors__image--default-4 {
  background-size: contain;
  background-image: url("/assets/private/avatar-3@2x.png");
}
.contributors__image--default-5 {
  background-size: contain;
  background-image: url("/assets/private/avatar-4@2x.png");
}
.contributors__image--default-6 {
  background-size: contain;
  background-image: url("/assets/private/avatar-5@2x.png");
}
.contributors__image--default-7 {
  background-size: contain;
  background-image: url("/assets/private/avatar-6@2x.png");
}
.contributors__total {
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.contributors__author {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.contributors__amount {
  font-size: 2rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #62c692;
}

.ev-wl-goal-item {
  border: 1px solid #e6e6e6;
}
.ev-wl-goal-item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.ev-wl-goal-item__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.ev-wl-goal-item__image--default {
  background-size: 50%;
  background-image: url("/assets/public/events/default-icon-goals@2x.png");
  border-style: solid;
  border-color: #e0e0e0;
}
.ev-wl-goal-item .details__title {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.ev-wl-goal-item .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.ev-wl-goal-item .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
}
.ev-wl-goal-item .button--upvote {
  background-image: url("/assets/private/up-vote.png");
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: 30px;
}

.submit-comment {
  border-style: solid;
  border-color: #e0e0e0;
}
.submit-comment--error {
  border-color: #a94442;
}
.submit-comment:focus, .submit-comment:hover {
  border-style: solid;
  border-color: #37a894;
}
.submit-comment__textarea .text-area__input {
  font-size: 1.4rem;
  line-height: 2rem;
}
.submit-comment__image-upload {
  display: flex;
  align-items: center;
}
.submit-comment__image-upload .comment-photo-upload__img {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/private/camera-comment@2x.png");
}
.submit-comment__image-upload .comment-photo-upload__img:hover {
  background-image: url("/assets/private/camera-comment-hover@2x.png");
}
.submit-comment__preview-wrapper {
  border-style: solid;
  border-color: #e0e0e0;
}
.submit-comment__preview-cancel {
  border-style: none;
  background-color: transparent;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/private/remove-photo-icon@2x.png");
}

.edit-comment {
  border-style: solid;
  border-color: #e0e0e0;
}
.edit-comment--error {
  border-color: #a94442;
}
.edit-comment:focus, .edit-comment:hover {
  border-style: solid;
  border-color: #37a894;
}
.edit-comment__textarea .text-area {
  border: none;
  outline: none;
}
.edit-comment__textarea .text-area:focus, .edit-comment__textarea .text-area:hover {
  border: none;
  outline: none;
}
.edit-comment__textarea .text-area__input {
  font-size: 1.4rem;
  line-height: 2rem;
}
.edit-comment__image-upload {
  display: flex;
  align-items: center;
}
.edit-comment__image-upload .comment-photo-upload__img {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/private/camera-comment@2x.png");
}
.edit-comment__image-upload .comment-photo-upload__img:hover {
  background-image: url("/assets/private/camera-comment-hover@2x.png");
}
.edit-comment__preview-wrapper {
  border-style: solid;
  border-color: #e0e0e0;
}
.edit-comment__preview-cancel {
  border-style: none;
  background-color: transparent;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/private/remove-photo-icon@2x.png");
}
.edit-comment__cancel {
  background-color: transparent;
  border-style: none;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #6c0062;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}

.join-event__title {
  font-size: 2.4rem;
  line-height: 4.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #000;
}
@media (max-width: 640px) {
  .join-event__title {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.contribute-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.contribute-container h2 {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.contribute-container .method-row__option {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  transition: all ease-in-out 0.3s;
}
.contribute-container .method-row__option:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.contribute-container .method-row__visa {
  background-image: url("/assets/private/visa@2x.png");
  margin-right: 10px;
}
.contribute-container .method-row__mastercard {
  background-image: url("/assets/private/mastercard@2x.png");
}
.contribute-container .method-row__ideal {
  background-image: url("/assets/private/ideal@2x.png");
}
.contribute-container .method-row span {
  color: #127b97;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}

.cash-out-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-container h2 {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-container h2 span {
  color: #62c692;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-container .method-row__option {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  transition: all ease-in-out 0.3s;
}
.cash-out-container .method-row__option:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.cash-out-container .method-row__bank {
  background-image: url("/assets/private/bank-account-icon@2x.png");
}
.cash-out-container .method-row__email {
  background-image: url("/assets/private/email-transfer-icon@2x.png");
}
.cash-out-container .method-row__debit {
  background-image: url("/assets/private/bank-transfer-icon@2x.png");
}
.cash-out-container .method-row span {
  color: #127b97;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-container .method-row p {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.contribute-card-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.contribute-form {
  border: 1px solid #cacaca;
  border-radius: 1rem;
}
@media (max-width: 640px) {
  .contribute-form {
    border: none !important;
  }
}
.contribute-form__visa {
  background-image: url("/assets/private/visa@2x.png");
}
.contribute-form__mastercard {
  background-image: url("/assets/private/mastercard@2x.png");
}
.contribute-form label {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.contribute-form h2 {
  color: #127b97;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}

.contribute-card-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.contribute-form {
  border: 1px solid #cacaca;
  border-radius: 1rem;
}
.contribute-form__visa {
  background-image: url("/assets/private/visa@2x.png");
}
.contribute-form__mastercard {
  background-image: url("/assets/private/mastercard@2x.png");
}
.contribute-form label {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.contribute-form h2 {
  color: #127b97;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}

.contribute-ideal-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.contribute-ideal-form {
  border: 1px solid #cacaca;
  border-radius: 1rem;
}
@media (max-width: 640px) {
  .contribute-ideal-form {
    border: none !important;
  }
}
.contribute-ideal-form__ideal {
  background-image: url("/assets/private/ideal@2x.png");
}
.contribute-ideal-form label {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.contribute-ideal-form h2 {
  color: #127b97;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}

.success-payment__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/assets/public/success-icon@3x.png");
}
.success-payment h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.success-payment span {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
}

.cash-out-email-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-email-container h2 {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-email-container h2 span {
  color: #62c692;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-email-container__subtitle {
  color: #127b97;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-email-container__subtitle span::before {
  content: "";
  vertical-align: -13%;
  width: 3.7rem;
  height: 2.5rem;
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 1rem;
  background-image: url("/assets/private/email-transfer-icon@2x.png");
}

.cash-out-email-form {
  border-top: 1px solid #cacaca;
}
@media (min-width: 768px) {
  .cash-out-email-form {
    border: 1px solid #cacaca;
    border-radius: 1rem;
  }
}
.cash-out-email-form label {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-email-form h3 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-email-form p {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.cash-out-debit-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-debit-container h2 {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-debit-container h2 span {
  color: #62c692;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-debit-container__subtitle {
  color: #127b97;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-debit-container__subtitle span::before {
  content: "";
  vertical-align: -13%;
  width: 3.7rem;
  height: 2.5rem;
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 1rem;
  background-image: url("/assets/private/bank-transfer-icon@2x.png");
}
.cash-out-debit-container__disclaimer {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.cash-out-debit-form label {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-debit-form h3 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-debit-form p {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-debit-form .personal-info__body {
  border-top: 1px solid #cacaca;
}
@media (min-width: 768px) {
  .cash-out-debit-form .personal-info__body {
    border: 1px solid #cacaca;
    border-radius: 1rem;
  }
}
.cash-out-debit-form .bank-account__iban {
  border-top: 1px solid #cacaca;
}
@media (min-width: 768px) {
  .cash-out-debit-form .bank-account__iban {
    border: 1px solid #cacaca;
    border-radius: 1rem;
  }
}

.cash-out-bank-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-bank-container h2 {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-bank-container h2 span {
  color: #62c692;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-bank-container__subtitle {
  color: #127b97;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-bank-container__subtitle span::before {
  content: "";
  vertical-align: -13%;
  width: 3.7rem;
  height: 2.5rem;
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 1rem;
  background-image: url("/assets/private/bank-account-icon@2x.png");
}
.cash-out-bank-container__disclaimer {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.cash-out-bank-form label {
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-bank-form h3 {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-bank-form p {
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-bank-form .personal-info__body {
  border-top: 1px solid #cacaca;
}
@media (min-width: 768px) {
  .cash-out-bank-form .personal-info__body {
    border: 1px solid #cacaca;
    border-radius: 1rem;
  }
}
.cash-out-bank-form .bank-account__iban {
  border-top: 1px solid #cacaca;
}
@media (min-width: 768px) {
  .cash-out-bank-form .bank-account__iban {
    border: 1px solid #cacaca;
    border-radius: 1rem;
  }
}

.cash-out-bank-container--us .bank-account {
  border: 1px solid #cacaca;
  border-radius: 1rem;
}
.cash-out-bank-container--us .bank-account__field label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.cash-out-bank-container--us .bank-account-info__success-card {
  border: 1px solid #cacaca;
  border-radius: 1rem;
}
.cash-out-bank-container--us .bank-account-info__success-card p {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-bank-container--us .bank-account-info__success-card p:before {
  background-image: url("/assets/private/verified@2x.png");
}
.cash-out-bank-container--us .bank-account-info__card {
  border-radius: 10px;
  background-color: #f2f2f2;
}
.cash-out-bank-container--us .bank-account-info__card .property {
  font-size: 1.4rem;
}
.cash-out-bank-container--us .bank-account-info__card .property__label {
  color: #9a9a9a;
}
.cash-out-bank-container--us .bank-account-info__card .property__value {
  color: #434343;
}
.cash-out-bank-container--us .bank-account-info__footer p {
  color: #434343;
  font-size: 16px;
  line-height: 20px;
}

.cash-out-bank-container--us .personal-info .field label,
.cash-out-debit-container .personal-info .field label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-bank-container--us .personal-info .field.has-ssn .clear-ssn,
.cash-out-debit-container .personal-info .field.has-ssn .clear-ssn {
  background-color: #fff;
  color: #434343;
  font-size: 3rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-weight: 900;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.cash-out-bank-container--us .personal-info .field.has-ssn .clear-ssn:hover,
.cash-out-debit-container .personal-info .field.has-ssn .clear-ssn:hover {
  color: #d0021b;
}
.cash-out-bank-container--us .personal-info__success-card,
.cash-out-debit-container .personal-info__success-card {
  border: 1px solid #cacaca;
  border-radius: 1rem;
}
.cash-out-bank-container--us .personal-info__success-card p,
.cash-out-debit-container .personal-info__success-card p {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-bank-container--us .personal-info__success-card p:before,
.cash-out-debit-container .personal-info__success-card p:before {
  background-image: url("/assets/private/verified@2x.png");
}

.cash-out-bank-container--eu .personal-info .field label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-bank-container--eu .personal-info__success-card {
  border: 1px solid #cacaca;
  border-radius: 1rem;
}
.cash-out-bank-container--eu .personal-info__success-card p {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-bank-container--eu .personal-info__success-card p:before {
  background-image: url("/assets/private/verified@2x.png");
}

.event-wishlist__title {
  font-size: 2.4rem;
  line-height: 4.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #000;
}

.details-empty-state__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.details-empty-state__image--comments {
  background-image: url("/assets/private/empty-states/discuss-empty-state@2x.png");
}
.details-empty-state__image--contributors {
  background-image: url("/assets/private/empty-states/contributors-empty-state@2x.png");
}
.details-empty-state__image--invited {
  background-image: url("/assets/private/empty-states/invite-empty-state@2x.png");
}
.details-empty-state__image--participant {
  background-image: url("/assets/private/empty-states/invite-empty-state@2x.png");
}
.details-empty-state h1 {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
  line-height: 2.2rem;
  font-size: 1.6rem;
}
.details-empty-state p {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
  font-size: 1.6rem;
  line-height: 2.2rem;
}
.details-empty-state--mobile h1 {
  font-size: 2.4rem;
  line-height: 3rem;
}

.cash-out-bank-container--eu .bank-account-info {
  border: 1px solid #cacaca;
  border-radius: 1rem;
}
.cash-out-bank-container--eu .bank-account-info__card {
  border-radius: 10px;
  background-color: #f2f2f2;
}
.cash-out-bank-container--eu .bank-account-info__card .property {
  font-size: 1.4rem;
}
.cash-out-bank-container--eu .bank-account-info__card .property__label {
  color: #9a9a9a;
}
.cash-out-bank-container--eu .bank-account-info__card .property__value {
  color: #434343;
}
.cash-out-bank-container--eu .bank-account-info__header {
  padding-top: 2rem;
}
.cash-out-bank-container--eu .bank-account-info__header p {
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.cash-out-bank-container--eu .bank-account-info__header p:before {
  background-image: url("/assets/private/verified@2x.png");
}

.cash-out-bank-container--eu .bank-account .iban__field label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.cash-out-bank-identity__verified p {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.cash-out-bank-identity__verified p:before {
  background-image: url("/assets/private/verified@2x.png");
}

.goals-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-size: 3rem;
  line-height: 3rem;
}
.goals-container--mobile h1 {
  font-size: 2.4rem;
}

.create-goal__field-label {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.create-goal__field-label--light {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.create-goal__error-label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #d0021b;
}
.create-goal__disclaimer {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}

.create-goal__field-label {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.create-goal__field-label--light {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.create-goal__error-label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #d0021b;
}
.create-goal__disclaimer {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.create-goal__description {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 3.4rem;
  color: #127b97;
}
.create-goal__field-product_search--edit {
  color: #6c0062;
}

.item {
  border: 1px solid #e6e6e6;
  background-color: #fff;
}
.item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.item__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.item__image--default {
  background-size: 50%;
  background-image: url("/assets/public/events/default-icon-goals@2x.png");
  border-style: solid;
  border-color: #e0e0e0;
}
.item .details__title {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.item .details__status {
  font-size: 1rem;
  line-height: 1.4rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  background-color: #eaeaea;
  color: #434343;
}
.item .details .actions__button {
  background-color: #eaeaea;
  background-size: 14px auto;
  background-position: center;
  background-repeat: no-repeat;
  border-style: none;
}
.item .details .actions__button--edit {
  background-image: url("/assets/public/edit-icon@2x.png");
}
.item .details .actions__button--delete {
  background-image: url("/assets/public/delete-icon@2x.png");
}
.item .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.item .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.item .details__content .property__value.offers-length {
  background-color: #6b0061;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #fff;
}

.item.item--mobile {
  margin-bottom: 5px;
  border-radius: 0;
}
.item.item--mobile .add-remove__button {
  font-size: 1.4rem;
  line-height: 1.4rem;
}
.item.item--mobile .dropdown .button--trigger {
  background-color: #eaeaea;
  border-right-color: #b2b2b2;
  border-style: solid;
}
.item.item--mobile .dropdown .button--trigger span {
  color: #b2b2b2;
}
.item.item--mobile .dropdown .button--trigger:hover span {
  margin: 0 0.5px;
  color: #810075;
}
.item.item--mobile .dropdown--active .button--trigger span {
  margin: 0 0.5px;
  color: #810075;
}
.item.item--mobile .dropdown__content {
  background-color: #fff;
}
.item.item--mobile .dropdown__content .action-link {
  font-size: 14px;
  color: #434343;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  cursor: pointer;
}
.item.item--mobile .dropdown__content .action-link:hover {
  color: #580c79;
}
.item.item--mobile .dropdown__content .delete-link {
  color: #6c0062;
}
.item.item--mobile .dropdown--active > .dropdown__content {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.goals-list__container--mobile .page-title {
  font-size: 2.8rem;
  line-height: 4.8rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.goals-list__container--mobile .toggle {
  padding: 13px;
  background-color: #eaeaea;
  border-radius: 10px;
  width: 40px;
  height: 40px;
}
.goals-list__container--mobile .toggle__icon {
  background-image: url("/assets/private/event-details/filter-icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 15px;
  display: block;
  height: 15px;
}
.goals-list__container--mobile .toggle__icon--close {
  background-image: url("/assets/public/close-modal-icon.svg");
}
.goals-list__selector-container .selector__label {
  color: #434343;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
}

.goal-mobile {
  border-bottom: #e6e6e6;
  border-bottom-width: 1px;
  border-style: solid;
}
.goal-mobile .add-remove__button {
  font-size: 1.4rem;
  line-height: 1.4rem;
}
.goal-mobile__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.goal-mobile__image--default {
  background-size: 50%;
  border-style: solid;
  border-color: #e0e0e0;
  background-image: url("/assets/public/events/default-icon-goals@2x.png");
}
.goal-mobile .details__title {
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.goal-mobile .details__status {
  font-size: 1rem;
  line-height: 1.4rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  background-color: #eaeaea;
  color: #434343;
}
.goal-mobile .details__priority {
  font-size: 1.4rem;
  line-height: 1.4rem;
  margin-top: 0.5rem;
  color: #434343;
}
.goal-mobile .details__priority--value {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.goal-mobile .details__gift-link {
  color: #6b0061;
}
.goal-mobile .details .actions {
  background-color: #f2f2f2;
}
.goal-mobile .details .actions::after {
  background-color: #f2f2f2;
}
.goal-mobile .details .actions__button {
  background-color: #fff;
  background-size: 14px auto;
  background-position: center;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  opacity: 0.8;
  border-color: #e6e6e6;
  transition: 0.2s ease-out;
}
.goal-mobile .details .actions__button--edit {
  background-image: url("/assets/public/edit-icon@2x.png");
}
.goal-mobile .details .actions__button--delete {
  background-image: url("/assets/public/delete-icon@2x.png");
}
.goal-mobile .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.goal-mobile .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.goal__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.goal__image--default {
  background-size: 50%;
  border-style: solid;
  border-color: #e0e0e0;
  background-image: url("/assets/public/events/default-icon-goals@2x.png");
}
.goal .details__title {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.goal .details__status {
  font-size: 1rem;
  line-height: 1.4rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  background-color: #eaeaea;
  color: #434343;
}
.goal .details__gift-link {
  color: #6b0061;
}
.goal .details .actions__button {
  background-color: #eaeaea;
  background-size: 14px auto;
  background-position: center;
  background-repeat: no-repeat;
  border-style: none;
}
.goal .details .actions__button--edit {
  background-image: url("/assets/public/edit-icon@2x.png");
}
.goal .details .actions__button--delete {
  background-image: url("/assets/public/delete-icon@2x.png");
}
.goal .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.goal .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}

.product-offers__container-badge {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.product-offers__container-badge span {
  background-color: #6b0061;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #fff;
}
.product-offers__list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5rem;
}
.product-offers__list::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  padding-right: 0.2rem;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
}
.product-offers__list::-webkit-scrollbar-track {
  background-color: transparent;
}
.product-offers__list-container {
  border-color: #e8e8e8;
  border-style: solid;
}
.product-offers__list-container:first-child {
  border: none;
}
.product-offers__list-item--icon {
  border-style: solid;
  border-color: #cacaca;
}
.product-offers__list-item--icon.amazon {
  background-image: url("/assets/public/amazon-logo.jpg");
}
.product-offers__list-item--icon.bol {
  background-image: url("/assets/public/bol-logo.png");
}
.product-offers__list-item--title {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  overflow: hidden;
}
.product-offers__list-item--title-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.product-offers__list-item--title-price {
  color: #62c692;
}
.product-offers__list-item--link {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #6b0061;
}
.product-offers .empty .product-offers__list-item span {
  display: block;
  height: 2.2rem;
  border-radius: 0.5rem;
}
.product-offers .empty .product-offers__list-item--icon {
  background-color: #e8e8e8;
}
.product-offers .empty .product-offers__list-item--title span:first-child {
  background-color: #e8e8e8;
  width: 250px;
}
.product-offers .empty .product-offers__list-item--title-text {
  text-overflow: ellipsis;
  overflow: hidden;
}
.product-offers .empty .product-offers__list-item--title-price {
  background-color: #e8e8e8;
  width: 100px;
  margin-top: 1rem;
}
.product-offers .empty .product-offers__list-item--link {
  background-color: #e8e8e8;
  width: 100px;
}
.product-offers--mobile {
  margin-left: 0;
}
.product-offers--mobile .product-offers__list-item--title {
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.product-offers--mobile .product-offers__list-item--link {
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.product-offers__item-info--icon {
  background-size: 50%;
  border-style: solid;
  border-color: #e0e0e0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
.product-offers__item-info--title {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2.7rem;
  color: #434343;
}
.product-offers__item-info--price .property-label {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
  text-transform: uppercase;
}
.product-offers__item-info--price .property-value {
  font-size: 2rem;
  line-height: 2.7rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.product-offers__item-info--description::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5rem;
}
.product-offers__item-info--description::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  padding-right: 0.2rem;
  background-color: #9a9a9a;
  box-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
}
.product-offers__item-info--description::-webkit-scrollbar-track {
  background-color: transparent;
}
.product-offers__item-info--description .property-label {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.7rem;
}
.product-offers__item-info--description .property-value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
}
.product-offers__item-info--price-icon {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: left center;
}
.product-offers__item-info--price-icon.amazon {
  background-image: url("/assets/public/amazon-logo.jpg");
}
.product-offers__item-info--price-icon.bol {
  background-image: url("/assets/public/bol-logo.png");
}
.product-offers__item-info--mobile .product-offers__item-info--title {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.product-offers__item-info--mobile .product-offers__item-info--description {
  font-size: 1.4rem;
  line-height: 2rem;
}

.wishlist-picker__add {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #6c0062;
  border-style: none;
  background-color: transparent;
  cursor: pointer;
}
.wishlist-picker__add:focus {
  outline: none;
}
.wishlist-picker__add-button {
  font-size: 1rem;
  border: 1px solid #6c0062;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.wishlist-picker__add-image {
  background-image: url("/assets/public/plus-icon@2x.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.wishlist-picker .wishlist__item {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  text-transform: capitalize;
}
.wishlist-picker .wishlist__item:nth-of-type(n + 5) {
  display: none;
}
.wishlist-picker .wishlist__item:nth-child(4):after {
  content: "...";
}

.wishlist-select__title {
  font-size: 2.4em;
  font-weight: 500;
}
.wishlist-select__list {
  border-style: solid;
  border-color: #e6e6e6;
}
.wishlist-select .wishlist {
  border-style: solid;
  border-color: #e6e6e6;
}
.wishlist-select .wishlist__go-to {
  max-width: 273px;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #9a9a9a;
}
.wishlist-select .wishlist__image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/assets/public/events/calendar-custom-event-icon.svg");
}
.wishlist-select .wishlist__image--baby {
  background-image: url("/assets/public/events/baby-icon.png");
}
.wishlist-select .wishlist__image--bachelor {
  background-image: url("/assets/public/events/bachelor-icon.png");
}
.wishlist-select .wishlist__image--birthday {
  background-image: url("/assets/public/events/birthday-icon.png");
}
.wishlist-select .wishlist__image--christmas {
  background-image: url("/assets/public/events/christmas-icon.png");
}
.wishlist-select .wishlist__image--easter {
  background-image: url("/assets/public/events/easter-icon.png");
}
.wishlist-select .wishlist__image--farewell {
  background-image: url("/assets/public/events/farewell-icon.png");
}
.wishlist-select .wishlist__image--house-warming {
  background-image: url("/assets/public/events/house-warming-icon.png");
}
.wishlist-select .wishlist__image--vacation {
  background-image: url("/assets/public/events/vacation-icon.png");
}
.wishlist-select .wishlist__image--wedding {
  background-image: url("/assets/public/events/wedding-icon.png");
}
.wishlist-select .wishlist__image-circle {
  border-style: solid;
  border-color: #e0e0e0;
}
.wishlist-select .wishlist .content__title {
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.wishlist-select .wishlist .content__properties .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.wishlist-select .wishlist .content__properties .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.wishlist-select .wishlist .toggler__button {
  background-color: transparent;
  border-style: none;
}
.wishlist-select .wishlist .toggler__button:focus {
  outline: none;
}
.wishlist-select .wishlist .toggler__button--add {
  border-style: solid;
  border-color: #721067;
  color: #721067;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
}
.wishlist-select .wishlist .toggler__button--remove {
  background-image: url("/assets/public/selection-wishlist@2x.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.product-select__field-label {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.product-select__description-title {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.product-select__description-text {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.product-select__list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5rem;
}
.product-select__list::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  padding-right: 0.2rem;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
}
.product-select__list::-webkit-scrollbar-track {
  background-color: transparent;
}
.product-select__list-no-results {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
}
.product-select__list-api-error {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #d0021b;
}
.product-select__list-item--icon {
  border-style: solid;
  border-color: #cacaca;
}
.product-select__list-item--icon-placeholder {
  background-image: url("/assets/public/no-product-image@2x.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.product-select__list-item--title {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.product-select__list-item--title-price {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.product-select__list-item--title-price span {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #62c692;
}
.product-select__list-item:hover {
  background: rgba(0, 0, 0, 0.02);
}
.product-select__card-title {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.product-select__card-item {
  border-color: #cacaca;
  border-style: solid;
}
.product-select__card-item__action-button {
  background-image: url("/assets/public/delete-icon@2x.png");
  background-color: #eaeaea;
  background-size: 1.4rem auto;
}
.product-select__card-item:hover {
  background-color: transparent;
}
.product-select__card-text {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
}
.product-select--mobile .product-select__card-title {
  font-size: 2rem;
  line-height: 3rem;
}
.product-select--mobile .product-select__card-text {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.product-offers-search__field,
.product-offers-search-mobile__field {
  border-color: #eaeaea;
  border-style: solid;
}
.product-offers-search__field--title,
.product-offers-search-mobile__field--title {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.product-offers-search__field--text,
.product-offers-search-mobile__field--text {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.product-offers-search__field--image,
.product-offers-search-mobile__field--image {
  background-image: url("/assets/public/running-lady-big@2x.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.product-offers-search__field--details,
.product-offers-search-mobile__field--details {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  font-style: italic;
  color: #9a9a9a;
}

.product-offers-search-mobile__field {
  border-radius: 0;
  border-right: none;
  border-left: none;
}

.product-picker__container .button--secondary {
  color: #6b0061;
}

.create-wish-list__title {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  line-height: 4.2rem;
  font-size: 3rem;
}
.create-wish-list--mobile .create-wish-list__title {
  font-size: 2.4rem;
  line-height: 3.6rem;
}

.wl-goal-item__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.wl-goal-item__image--default {
  background-size: 50%;
  border-style: solid;
  border-color: #e0e0e0;
  background-image: url("/assets/public/events/default-icon-goals@2x.png");
}
.wl-goal-item__image--wishlists-count {
  color: #fff;
  background-color: #721067;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}
.wl-goal-item__image--heart {
  background-image: url("/assets/public/heart-icon@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.wl-goal-item__details .details__title {
  color: #127b97;
  font-size: 2.4rem;
  font-weight: bold;
}
.wl-goal-item__details .details__actions .actions__button--remove {
  background-color: #eaeaea;
  color: #434343;
  line-height: 2.2rem;
  height: 3.4rem;
  min-width: 8rem;
}
.wl-goal-item__details .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.wl-goal-item__details .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
  font-weight: 500;
}
.wl-goal-item--mobile .details__title {
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.wl-goal-item--mobile .details__actions .actions__button--remove {
  font-size: 1.3rem;
  line-height: 2rem;
  height: 3rem;
  padding: 0 1rem;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
}
.wl-goal-item--mobile .details__content .property__label {
  line-height: 1.8rem;
}
.wl-goal-item--mobile .details__content .property__value {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.wishlist-detail__title {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 3rem;
  color: #434343;
}
.wishlist-detail__button--remove {
  background-image: url("/assets/public/delete-icon@2x.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  width: 52px;
}
.wishlist-detail .grid__item {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}
.wishlist-detail .grid__item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.wishlist-detail--mobile button {
  font-size: 1.4rem;
}
.wishlist-detail--mobile .wishlist-detail__title {
  font-size: 2.4rem;
}

.wishlist-add-goals__title {
  font-weight: bold;
  font-size: 3rem;
  color: #434343;
}
.wishlist-add-goals__title--sub {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.5rem;
  color: #b2b2b2;
}
.wishlist-add-goals--mobile .wishlist-add-goals__title {
  font-size: 2rem;
}
.wishlist-add-goals--mobile .wishlist-add-goals__title--sub {
  font-size: 1.4rem;
}
.wishlist-add-goals--mobile .button--large {
  min-width: 12rem;
}

.wl-create-goal-item {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}
.wl-create-goal-item__image {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #e6e6e6;
}
.wl-create-goal-item__image--add {
  background-image: url("/assets/public/create-plus-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
}
.wl-create-goal-item__details .details__title {
  color: #127b97;
  font-size: 2.4rem;
  font-weight: bold;
}
.wl-create-goal-item--mobile .details__title {
  font-size: 2rem;
}

.wl-add-goal-item {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}
.wl-add-goal-item__image {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.wl-add-goal-item__image--default {
  background-size: 50%;
  border-style: solid;
  border-color: #e0e0e0;
  background-image: url("/assets/public/events/default-icon-goals@2x.png");
}
.wl-add-goal-item__image--wishlists-count {
  color: #fff;
  background-color: #721067;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}
.wl-add-goal-item__image--heart {
  background-image: url("/assets/public/heart-icon@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.wl-add-goal-item__details .details__title {
  color: #127b97;
  font-size: 2.4rem;
  font-weight: bold;
}
.wl-add-goal-item__details .details__actions .actions__button--remove {
  border-radius: 10px;
  background-color: #eaeaea;
  font-size: 1.6rem;
  color: #434343;
}
.wl-add-goal-item__details .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.wl-add-goal-item__details .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
}
.wl-add-goal-item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.wl-add-goal-item--mobile .details__title {
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.wl-add-goal-item--mobile .details__actions .actions__button--remove {
  font-size: 1.3rem;
  line-height: 2rem;
  height: 3rem;
  padding: 0 1rem;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
}
.wl-add-goal-item--mobile .details__content .property__label {
  line-height: 1.8rem;
}
.wl-add-goal-item--mobile .details__content .property__value {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.share-wishlist__title {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  line-height: 3.4rem;
  font-size: 2.4rem;
}
.share-wishlist__subtitle {
  font-size: 1.6rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.share-wishlist label {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-weight: 500;
}
.share-wishlist__social-title {
  font-size: 1.4rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-weight: 500;
}
.share-wishlist--mobile .share-wishlist__title {
  font-size: 2.2rem;
}

.wishlist_invitees__title {
  color: #127b97;
  font-size: 1.6rem;
  line-height: 22px;
  font-weight: bold;
}
.wishlist_invitees__list {
  border: 1px solid #eee;
  border-radius: 10px;
}

.rq-wishlists__title {
  font-size: 3rem;
  color: #434343;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.rq-wishlists__subtitle {
  font-size: 1.6rem;
  color: #434343;
}
.rq-wishlists .grid__item {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}
.rq-wishlists .grid__item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.rq-wl-goal-item__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rq-wl-goal-item__image--default {
  background-size: 50%;
  border-style: solid;
  border-color: #e0e0e0;
  background-image: url("/assets/public/events/default-icon-goals@2x.png");
}
.rq-wl-goal-item__image--wishlists-count {
  color: #fff;
  background-color: #721067;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}
.rq-wl-goal-item__image--heart {
  background-image: url("/assets/public/heart-icon@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.rq-wl-goal-item__details .details__title {
  color: #127b97;
  font-size: 2.4rem;
  font-weight: bold;
}
.rq-wl-goal-item__details .details__actions .actions__button--remove {
  border-radius: 10px;
  background-color: #eaeaea;
  font-size: 1.6rem;
  color: #434343;
}
.rq-wl-goal-item__details .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.rq-wl-goal-item__details .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
}

.empty-state__image--wishlist-empty-list {
  background-image: url("/assets/private/empty-states/wishlist-empty-list@2x.png");
}

.wishlists .grid__item {
  border-style: solid;
  border-radius: 10px;
  border-color: #eee;
  background-color: #fff;
}
.wishlists .grid__item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.create-wish-list__field-label {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.create-wish-list__error-label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #d0021b;
}

.public-wishlist-detail__title {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.public-wishlist-detail__subtitle {
  font-size: 1.6rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.public-wishlist-detail label {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
  font-weight: 500;
}

.pb-wl-goal-item {
  border: 1px solid #e6e6e6;
}
.pb-wl-goal-item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.pb-wl-goal-item__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.pb-wl-goal-item__image--default {
  background-size: 50%;
  background-image: url("/assets/public/events/default-icon-goals@2x.png");
  border-style: solid;
  border-color: #e0e0e0;
}
.pb-wl-goal-item .details__title {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.pb-wl-goal-item .details__status {
  font-size: 1rem;
  line-height: 1.4rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  background-color: #eaeaea;
  color: #434343;
}
.pb-wl-goal-item .details__content .property__label {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.pb-wl-goal-item .details__content .property__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
}
.pb-wl-goal-item--mobile .details__title {
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.pb-wl-goal-item--mobile .details__actions .actions__button--remove {
  font-size: 1.3rem;
  line-height: 2rem;
  height: 3rem;
  padding: 0 1rem;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
}
.pb-wl-goal-item--mobile .details__content .property__label {
  line-height: 1.8rem;
}
.pb-wl-goal-item--mobile .details__content .property__value {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.redux-toastr {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.redux-toastr .toastr {
  box-shadow: none;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.redux-toastr .toastr .rrt-middle-container {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
@media (max-width: 640px) {
  .redux-toastr .toastr .rrt-middle-container {
    font-size: 1.4rem !important;
    line-height: 2rem;
  }
}
.redux-toastr .toastr .toastr-status.success {
  background-color: #62c692;
}
.redux-toastr .toastr .toastr-status.info {
  background-color: #62c692;
}
.redux-toastr .toastr .toastr-status.warning {
  background-color: #ff7b3b;
}
.redux-toastr .toastr .toastr-status.error {
  background-color: #fa2067;
}
.redux-toastr .toastr .close-toastr {
  background-color: #f2f2f2;
  color: #000;
  opacity: 0.5;
}
.redux-toastr .toastr .close-toastr:hover {
  opacity: 1;
}
.redux-toastr .toastr .close-toastr:focus {
  outline: none;
}
.redux-toastr .toastr.rrt-error {
  background-color: #fa2067;
}
.redux-toastr .toastr.rrt-error .rrt-progressbar {
  background-color: rgb(200.9934210526, 4.5065789474, 68.5);
}

.toastr--cookies {
  background-color: #37a995 !important;
}

.cookie-toastr {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.cookie-toastr__actions {
  margin-top: 10px;
  font-size: 1.7rem;
}
.cookie-toastr__actions--more-info {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #fff;
}
.cookie-toastr__actions--confirm {
  border-radius: 10px;
  background-color: #fa2067;
  border: none;
  color: #fff;
  font-size: inherit;
}
@media (max-width: 640px) {
  .cookie-toastr__actions {
    font-size: 1.4rem;
  }
}

.coachmark__overlay {
  background-color: rgba(0, 0, 0, 0.47);
}

.toastr--coachmark {
  background-color: #fff !important;
}
.toastr.goal-details {
  background-color: #62c692 !important;
}
.toastr.goal-details .rrt-title {
  display: none;
}
.toastr.goal-details .coachmark__title, .toastr.goal-details .coachmark__body {
  color: #fff;
}
.toastr.goal-details .coachmark .button--invisible {
  background: transparent !important;
  color: #fff;
}
.toastr.goal-details .coachmark--mobile .coachmark__title {
  font-size: 2.2rem;
}

.coachmark__title {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.coachmark__body {
  color: #434343;
  font-size: 1.6rem;
}
.coachmark__icon {
  background-size: contain;
  background-repeat: no-repeat;
}
.coachmark__icon--events {
  background-image: url("/assets/public/coachmark-events@2x.png");
}
.coachmark__icon--goals {
  background-image: url("/assets/public/coachmark-goals@2x.png");
}
.coachmark__icon--wishlists {
  background-image: url("/assets/public/coachmark-wishlists@2x.png");
}
.coachmark__icon--goal-details {
  background-image: url("/assets/public/extension-img.png");
}

.notifications-list__title {
  font-size: 2.4rem;
  font-weight: bold;
  color: #434343;
}

.notification-item {
  border-style: solid;
  border-color: #eaeaea;
}
.notification-item--selected {
  background-color: #f8f8f8;
}
.notification-item__image {
  border-style: solid;
  background-color: #fff;
  border-color: #e0e0e0;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
}
.notification-item__image--time_left_three_days {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notification-item__image--contribution {
  background-image: url("/assets/public/notifications/contributions@2x.png");
}
.notification-item__image--goal_amount_reached {
  background-image: url("/assets/public/notifications/reached-target@2x.png");
}
.notification-item__image--wishlist_created {
  background-image: url("/assets/public/notifications/shared-wishlist@2x.png");
}
.notification-item__image--wishlist_request_no_account {
  background-image: url("/assets/public/notifications/birthday-coming-up@2x.png");
}
.notification-item__image--wishlist_request_acknowledge {
  background-image: url("/assets/public/notifications/birthday-coming-up@2x.png");
}
.notification-item__image--wishlist_request_no_goals {
  background-image: url("/assets/public/notifications/birthday-coming-up@2x.png");
}
.notification-item__image--wishlist_request_account_goals {
  background-image: url("/assets/public/notifications/birthday-coming-up@2x.png");
}
.notification-item__image--vote {
  background-image: url("/assets/public/notifications/voted@2x.png");
}
.notification-item__image--event_invitation {
  background-image: url("/assets/public/notifications/join-event@2x.png");
}
.notification-item__image--event_sharing {
  background-image: url("/assets/public/notifications/join-event@2x.png");
}
.notification-item__image--wishlist_sharing {
  background-image: url("/assets/public/notifications/join-event@2x.png");
}
.notification-item__image--spend_money_via_email {
  background-image: url("/assets/public/notifications/raised@2x.png");
}
.notification-item__image--bank_transfer_success {
  background-image: url("/assets/public/notifications/raised@2x.png");
}
.notification-item__image--bank_transfer_failure {
  background-image: url("/assets/public/notifications/transfer-error@2x.png");
}
.notification-item__image--comment {
  background-image: url("/assets/public/notifications/comment@2x.png");
}
.notification-item__image--contribution_unregistered_user {
  background-image: url("/assets/public/notifications/contributions@2x.png");
}
.notification-item__image--inconsistent_transaction {
  background-image: url("/assets/public/notifications/transfer-error@2x.png");
}
.notification-item__image--extra_authentication_needed {
  background-image: url("/assets/public/notifications/birthday-coming-up@2x.png");
}
.notification-item__image--time_left_to_contribute {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notification-item__image--time_left_to_cash_out {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notification-item__image--time_left_0_days_to_cashout {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notification-item__details {
  font-size: 1.6rem;
}
.notification-item .details__title {
  font-size: 1.6rem;
}
.notification-item .details__time {
  font-size: 1.4rem;
  color: #9a9a9a;
}
.notification-item__actions--remove {
  border: none;
  background-image: url("/assets/public/remove-icon@2x.png");
  background-color: transparent;
  opacity: 0.39;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.notification-item__actions--remove:hover {
  opacity: 1;
}

.notifications-menu {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/assets/public/notification-icon@2x.png");
}
.notifications-menu__count {
  background-color: #fd0d60;
  border-radius: 16px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
}

.notifications-nav-item {
  border-style: solid;
  border-color: #eaeaea;
  font-size: 1.4rem;
}
.notifications-nav-item--selected {
  background-color: #f8f8f8;
}
.notifications-nav-item__image {
  border-style: solid;
  background-color: #fff;
  border-color: #e0e0e0;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
}
.notifications-nav-item__image--time_left_three_days {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item__image--contribution {
  background-image: url("/assets/public/notifications/contributions@2x.png");
}
.notifications-nav-item__image--goal_amount_reached {
  background-image: url("/assets/public/notifications/reached-target@2x.png");
}
.notifications-nav-item__image--wishlist_created {
  background-image: url("/assets/public/notifications/shared-wishlist@2x.png");
}
.notifications-nav-item__image--wishlist_request_no_account {
  background-image: url("/assets/public/notifications/birthday-coming-up@2x.png");
}
.notifications-nav-item__image--wishlist_request_acknowledge {
  background-image: url("/assets/public/notifications/birthday-coming-up@2x.png");
}
.notifications-nav-item__image--wishlist_request_no_goals {
  background-image: url("/assets/public/notifications/birthday-coming-up@2x.png");
}
.notifications-nav-item__image--wishlist_request_account_goals {
  background-image: url("/assets/public/notifications/birthday-coming-up@2x.png");
}
.notifications-nav-item__image--vote {
  background-image: url("/assets/public/notifications/voted@2x.png");
}
.notifications-nav-item__image--event_invitation {
  background-image: url("/assets/public/notifications/join-event@2x.png");
}
.notifications-nav-item__image--event_sharing {
  background-image: url("/assets/public/notifications/join-event@2x.png");
}
.notifications-nav-item__image--wishlist_sharing {
  background-image: url("/assets/public/notifications/join-event@2x.png");
}
.notifications-nav-item__image--spend_money_via_email {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item__image--bank_transfer_success {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item__image--bank_transfer_failure {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item__image--comment {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item__image--contribution_unregistered_user {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item__image--inconsistent_transaction {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item__image--extra_authentication_needed {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item__image--time_left_to_contribute {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item__image--time_left_to_cash_out {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item__image--time_left_0_days_to_cashout {
  background-image: url("/assets/public/notifications/days-left@2x.png");
}
.notifications-nav-item .details__time {
  font-size: 1.2rem;
  color: #9a9a9a;
}
.notifications-nav-item__actions--remove {
  border: none;
  background-image: url("/assets/public/remove-icon@2x.png");
  background-color: transparent;
  opacity: 0.39;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.notifications-nav-item__actions--remove:hover {
  opacity: 1;
}

.notifications-nav {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.11);
}
.notifications-nav__title {
  border-bottom: 1px solid #eaeaea;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #9a9a9a;
}
.notifications-nav__footer {
  font-size: 1.6rem;
  text-align: center;
  border-top: 1px solid #eaeaea;
}
.notifications-nav__footer a {
  color: #6c0062 !important;
}
.notifications-nav__footer a.disabled {
  pointer-events: none;
  color: #aaa !important;
  cursor: default;
}

.private .header__navigation .profile-menu__user-name {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #434343;
}
.private .header__navigation .profile-menu__user-email {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #434343;
}
.private .header__navigation .profile-menu__link a {
  font-size: 1.6rem;
  line-height: 4rem;
  color: #6c0062;
}

.profile .profile-tabs__tab-list {
  border-style: solid;
  border-color: #cacaca;
  border-radius: 10px;
}
.profile .profile-tabs__tab {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
}
.profile .profile-tabs__tab:not(:last-of-type) {
  border-style: solid;
  border-color: #cacaca;
}
.profile .profile-tabs__tab:focus {
  outline: none;
}
.profile .profile-tabs__tab.react-tabs__tab--selected {
  color: #6c0062;
}
.profile .profile-tabs__tab-section {
  border-style: solid;
  border-color: #cacaca;
  border-radius: 10px;
}
.profile .profile-tabs__tab-title {
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.profile__title {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #037b99;
}
.profile--mobile .profile-tabs__tab-list {
  border: none;
}
.profile--mobile .profile-tabs__tab {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
  border-style: solid;
  border-color: #cacaca;
  border-radius: 10px;
  border-width: 1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.profile--mobile .profile-tabs__tab:focus {
  outline: none;
}
.profile--mobile .profile-tabs__tab.react-tabs__tab--selected {
  color: #6c0062;
}
.profile--mobile .profile-tabs__tab-section {
  border-radius: 0;
  border-style: solid;
  border-color: #cacaca;
  border-width: 0 !important;
}
.profile--mobile .profile-tabs__tab-section:not(:first-of-type) {
  border-top-width: 1px !important;
}
.profile--mobile .profile-tabs__tab-title {
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
}
.profile--mobile .profile-tabs__tab-wrapper {
  border-style: solid;
  border-color: #cacaca;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.profile-photo-upload__preview {
  background-image: url("/assets/private/user_portrait.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.profile-photo-upload__info {
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #434343;
}
.profile-photo-upload--mobile .profile-photo-upload__info {
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.profile-identity__preview {
  background-image: url("/assets/private/user_portrait.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.profile-identity__info {
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #434343;
}
.profile-identity__max-size {
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #9a9a9a;
}
.profile-identity__status {
  font-size: 1.4rem;
  line-height: 25px;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}
.profile-identity__status--light, .profile-identity__status--unverified, .profile-identity__status--refused {
  background-image: url("/assets/private/not-verified@2x.png");
}
.profile-identity__status--pending {
  background-image: url("/assets/private/pending@2x.png");
}
.profile-identity__status--regular, .profile-identity__status--verified {
  background-image: url("/assets/private/verified@2x.png");
}

.profile-identity-upload {
  border-style: solid;
  border-color: #cacaca;
}
.profile-identity-upload__name {
  color: #9a9a9a;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 10px 14px;
}
.profile-identity-upload__icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.profile-identity-upload__icon--upload {
  background-image: url("/assets/public/camera-field@3x.png");
}
.profile-identity-upload__icon--remove {
  background-image: url("/assets/public/remove-icon@2x.png");
}

.notifications-set__section-title {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: #434343;
}
.notifications-set__field {
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: #434343;
}

.language-selector-field__green-checkmark {
  background-image: url("/assets/public/check-mark copy@3x.png");
}

.language-label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #9a9a9a;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}
.language-label:hover {
  transition: all ease-in-out 0.3s;
  box-shadow: 0 0 7px 0 #e0e0e0;
}
.language-label--en-GB:before {
  background-image: url("/assets/private/en_flag.png");
}
.language-label--nl-NL:before {
  background-image: url("/assets/private/nl_flag.png");
}

.radio-input {
  border: 1px solid #e0e0e0;
}
.radio-input:checked + .language-label {
  border-radius: 10px;
  border: 1px solid #37a894;
}
.radio-input:checked + .language-label:hover {
  transition: all ease-in-out 0.3s;
  box-shadow: 0 0 7px 0 #37a894;
}

.bank-account-info__card {
  border-radius: 10px;
  background-color: #f2f2f2;
}
.bank-account-info__card .property {
  font-size: 1.4rem;
}
.bank-account-info__card .property__label {
  color: #9a9a9a;
}
.bank-account-info__card .property__value {
  color: #434343;
}
.bank-account-info__footer p {
  color: #434343;
  font-size: 16px;
  line-height: 20px;
}

.bank-account .iban__field label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.bank-account-info__card {
  border-radius: 10px;
  background-color: #f2f2f2;
}
.bank-account-info__card .property {
  font-size: 1.4rem;
}
.bank-account-info__card .property__label {
  color: #9a9a9a;
}
.bank-account-info__card .property__value {
  color: #434343;
}
.bank-account-info__footer p {
  color: #434343;
  font-size: 16px;
  line-height: 20px;
}

.bank-account__field label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.bank-account p {
  color: #434343;
  font-size: 16px;
  line-height: 20px;
}

.bank-account p {
  color: #434343;
  font-size: 16px;
  line-height: 20px;
}

.card-details-info__footer p {
  color: #434343;
  font-size: 16px;
  line-height: 20px;
}

.card-details-form__card-field label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.personal-info .field label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.personal-info .field label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.change-password .field label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}

.wishlist__item {
  border-color: #e9e9e9;
}
.wishlist__item__image {
  border-color: #e9e9e9;
}
.wishlist__item__details h2 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127B97;
}
.wishlist__item__amount span.label, .wishlist__item__retailer span.label {
  color: #9a9a9a;
}
.wishlist__item__amount span.value, .wishlist__item__retailer span.value {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.wishlist__item__amount a, .wishlist__item__retailer a {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #5d0055;
}

.wishlist__title h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}

.mobile-container__not-supported h3 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.mobile-container__not-supported p {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.mobile-container__background--android {
  background-image: url("/assets/public/mobile/background-droid@3x.png");
}
.mobile-container__background--ios {
  background-image: url("/assets/public/mobile/background-ios@3x.png");
}
.mobile-container__button--android {
  background-image: url("/assets/public/mobile/google-button@3x.png");
}
.mobile-container__button--ios {
  background-image: url("/assets/public/mobile/ios-button@3x.png");
}
.mobile-container__disclaimer {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  color: #fff;
}

.events-container.public h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.events-container.public h1:before {
  background-image: url("/assets/public/login-illustration.png");
}
.events-container.public--mobile h1 {
  font-size: 2.4rem;
}

.create-event__field-label {
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.create-event__field-label--light {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.create-event__error-label {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
  color: #d0021b;
}
.create-event__disclaimer {
  font-family: "Brandon Text", Arial, Helvetica, Verdana, sans-serif;
}
.create-event__wishlist-links {
  color: #6c0062;
  font-family: "Brandon Text Medium", Arial, Helvetica, Verdana, sans-serif;
}
.create-event__wishlist-links__label {
  color: #434343;
}
.create-event__wishlist-links__wrapper {
  background-color: #f2f2f2;
  border-color: #cacaca;
}

.welcome-container h1 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.welcome-container h1:before {
  background-image: url("/assets/public/login-illustration.png");
}
.welcome-container .logo {
  background-image: url("/assets/public/logo@3x.png");
}
.welcome-container .name {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
}
.welcome-container h2 {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #434343;
}
.welcome-container__separator {
  border-bottom-color: #e8e8e8;
}
.welcome-container__separator > span {
  border-color: #fff;
}
.welcome-container__separator > span span {
  background-color: #fff;
  border-color: #e8e8e8;
}

.wish-list__image {
  border: 1px solid #eee;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}
.wish-list__image--baby {
  background-image: url("/assets/public/events/babyshower.svg");
}
.wish-list__image--bachelor {
  background-image: url("/assets/public/events/bachelor.svg");
}
.wish-list__image--birthday {
  background-image: url("/assets/public/events/birthday.svg");
}
.wish-list__image--holiday {
  background-image: url("/assets/public/events/holiday.svg");
}
.wish-list__image--vacation {
  background-image: url("/assets/public/events/vacation.svg");
}
.wish-list__image--wedding {
  background-image: url("/assets/public/events/wedding.svg");
}
.wish-list__image--custom {
  background-image: url("/assets/public/events/custom1.svg");
}
.wish-list__image--farewell {
  background-image: url("/assets/public/events/farewell.svg");
}
.wish-list__image--house-warming {
  background-image: url("/assets/public/events/housewarming.svg");
}
.wish-list .main .description__column .column__label {
  color: #9a9a9a;
  font-size: 1rem;
  line-height: 1.4rem;
}
.wish-list .main .description__column .column__value {
  font-size: 1.6rem;
  line-height: 2.2rem;
}
.wish-list .main__title {
  font-family: "Brandon Text Bold", Arial, Helvetica, Verdana, sans-serif;
  color: #127b97;
  font-size: 2.4rem;
  line-height: 3rem;
}
.wish-list .main__share {
  text-align: right;
}
.wish-list .main__share--button {
  background-color: #eaeaea;
  border-radius: 10px;
  color: #434343;
  font-size: 1.6rem;
  line-height: 2.2rem;
  height: 3.4rem;
  padding: 0 14px;
}
.wish-list--mobile .main__title {
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}
.wish-list--mobile .main .description__column .column__value {
  font-size: 1.2rem;
  line-height: 1.8rem;
}
.wish-list--mobile .main .description__column .column__label {
  line-height: 1.8rem;
}
.wish-list--mobile .main__share--button {
  font-size: 1.3rem;
  line-height: 2rem;
  height: 3rem;
  padding: 0 1rem;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
}
